import { AxiosResponse } from 'axios';
import {
  Address,
  DealPartners,
  DealsItemPartnersResponse,
  ParticipantItemAddressesResponse,
  ParticipantPartner,
  Subdivision,
  SubdivisionsResponse,
} from '../api/marketx';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { AppDeal } from '../slices/AppDeal';
import { makeAutoObservable, runInAction } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';

/**
 * Партнеры клиента для управления из сделки
 */
export class DealPartnersStore {
  svc: DealPartnersService;
  apiStore: ApiStore;
  dealCode?: string;
  dealPartners?: DealPartners;
  branchOfficeCode?: string;

  isLoaded = false;
  isLoading = false;
  loadedEpoch = 0;

  payersList: ParticipantPartner[] = null;
  consigneesList: ParticipantPartner[] = null;
  subdivisionsList: Subdivision[] = null;
  addressesList: Address[] = null;

  constructor(rootStore: RootStore) {
    this.svc = new DealPartnersService();
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      svc: false,
      apiStore: false,
    });
  }

  reloadAddressesList(consigneeCode: string): Promise<void> {
    return this.svc.loadConsigneeAddresses(this, consigneeCode);
  }

  loadListForDeal(deal: AppDeal): void {
    const newDealCode = deal.code || undefined;
    if (this.dealCode === newDealCode && this.dealCode) {
      return;
    }
    runInAction(() => {
      this.dealCode = newDealCode;
      this.dealPartners = deal.partners;
      this.branchOfficeCode = deal?.branchOfficeCode || '';
      if (!newDealCode && this.dealCode) {
        this.setEmptyList();
        return;
      }
      this.svc.debounceRequest(this);
    });
  }

  setAddressesResult(ctx: AxiosCallContext, res: ParticipantItemAddressesResponse): void {
    this.addressesList = res.addresses;
  }
  setPartnersResult(ctx: AxiosCallContext, res: DealsItemPartnersResponse, partnerType: string): void {
    if (partnerType === 'payer') {
      this.payersList = res.partners;
    }
    if (partnerType === 'consignee') {
      this.consigneesList = res.partners;
    }
  }

  setEmptyList(): void {
    this.isLoaded = true;
    this.isLoading = false;
    this.loadedEpoch++;
  }
}

class DealPartnersService {
  requestDebounceTimeout: NodeJS.Timeout;

  debounceRequest(store: DealPartnersStore): void {
    if (this.requestDebounceTimeout) {
      clearTimeout(this.requestDebounceTimeout);
    }
    this.requestDebounceTimeout = setTimeout(() => {
      this.requestDebounceTimeout = undefined;
      this.executeRequest(store);
    }, 100);
  }

  async executeRequest(store: DealPartnersStore): Promise<void> {
    if (!store.dealCode) {
      return;
    }
    await store.apiStore
      .apiClientDeal()
      .dealsItemPartners(store.dealCode, 'payer')
      .then((res: AxiosResponse<DealsItemPartnersResponse>): void => {
        store.setPartnersResult(getCallContext(res), res.data, 'payer');
      })
      .catch(r => {
        console.warn('Не удалось загрузить плательщиков', r);
      });
    await store.apiStore
      .apiClientDeal()
      .dealsItemPartners(store.dealCode, 'consignee')
      .then((res: AxiosResponse<DealsItemPartnersResponse>): void => {
        store.setPartnersResult(getCallContext(res), res.data, 'consignee');
      })
      .catch(r => {
        console.warn('Не удалось загрузить грузополучателей', r);
      });
    await store.apiStore
      .apiSubdivision()
      .subdivisions({ branchOfficeCode: store.branchOfficeCode })
      .then((res: AxiosResponse<SubdivisionsResponse>): void => {
        runInAction(() => (store.subdivisionsList = res.data.subdivisions));
      })
      .catch(error => console.warn('Не удалось загрузить подразделения', error));
    if (store.dealPartners?.consignee?.customerCode) {
      await this.loadConsigneeAddresses(store, store.dealPartners?.consignee?.customerCode);
    }
  }

  async loadConsigneeAddresses(store: DealPartnersStore, consigneeCode: string): Promise<void> {
    try {
      return await store.apiStore
        .apiClientAddress()
        .addressList(consigneeCode, store.branchOfficeCode)
        .then((res: AxiosResponse<ParticipantItemAddressesResponse>): void => {
          store.setAddressesResult(getCallContext(res), res.data);
        });
    } catch (error) {
      console.warn('Не удалось загрузить грузополучателей', error);
    }
  }
}
