import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { BillPosition } from '../../../api/marketx';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { BillItemStore } from '../../../store/Documents/BillItemStore';
import TextField from '@mui/material/TextField';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { BillSummaryTabHeader } from '../BillSummaryTabHeader';
import { BillPositionAdditionalTabHeader } from './BillPositionAdditionalTabHeader';
import { useDebounce } from 'src/utils/hooks/useDebounce';

interface BillAdditionalTabComentProps {
  billStore: BillItemStore;
  position?: BillPosition;
  isDialogOpen?: boolean;
}

export const BillAdditionalTabComment: FC<BillAdditionalTabComentProps> = observer(({ billStore, position, isDialogOpen }): JSX.Element => {
  const bill = billStore.item;
  const currentCommentCode = position?.code || 'billSummary';

  const isEditing = bill?.canUpdateStoreComments && billStore.isEditing && !billStore.isSaving;

  const handleUpdateComments = useDebounce(() => {
    const comments = billStore.getComments();
    billStore.updateBill({ comments: comments });
  }, 1200);

  const handleEditValue = (value: string): void => {
    billStore.addNewComment(value, currentCommentCode);
    handleUpdateComments();
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems={'center'}>
        {position ? (
          <BillPositionAdditionalTabHeader Icon={ChatOutlinedIcon} position={position} isDialogOpen={isDialogOpen} />
        ) : (
          <BillSummaryTabHeader Icon={ChatOutlinedIcon} billItemStore={billStore} isDialogOpen={isDialogOpen} />
        )}
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 1, paddingLeft: 0 }}>
            Комментарий для склада
          </Typography>
        </Grid>
        <Grid item xs={12} container alignItems={'center'} wrap={'nowrap'}>
          <Grid item flex={'1 1 auto'}>
            <TextField
              sx={{ '& .MuiOutlinedInput-input': { fontWeight: '400!important' }, pr: 1 }}
              onChange={e => handleEditValue(e.target.value || '')}
              maxRows={3}
              type="text"
              fullWidth
              disabled={!isEditing}
              value={billStore.temporaryComments[currentCommentCode].comment}
              multiline
              size={'small'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
