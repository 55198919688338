import React, { FC, JSX, ReactNode, useState } from 'react';
import { Fab, Grid, Theme } from '@mui/material';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import { SxProps } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TabSelectWrapperProps {
  children: ReactNode;
  raiseBtnStyles?: SxProps<Theme>;
  infiniteScrollId?: string;
  onOpen?: (isOpen: boolean) => void;
}

export const TabSelectWrapper: FC<TabSelectWrapperProps> = ({ children, raiseBtnStyles, infiniteScrollId, onOpen }): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBtnShow, setIsBtnShow] = useState(false);
  const matchesXs = useMediaQuery('(min-width:600px)');
  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
    onOpen && onOpen(false);
  };
  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
    onOpen && onOpen(true);
  };
  const handleBtnShow = (): void => {
    setIsBtnShow(true);
  };
  const handleBtnHide = (): void => {
    setIsBtnShow(false);
  };

  return (
    <Grid width={'100%'} height={'100%'}>
      <Grid position={'relative'} height={'100%'} onMouseEnter={handleBtnShow} onMouseLeave={handleBtnHide}>
        {matchesXs && isBtnShow && (
          <Fab
            color="primary"
            size={'small'}
            onClick={handleDialogOpen}
            sx={{
              position: 'absolute',
              right: '20px',
              bottom: '20px',
              width: '30px',
              height: '30px',
              minHeight: '30px',
              ...raiseBtnStyles,
            }}
            title={'Развернуть'}
          >
            <AspectRatioOutlinedIcon sx={{ fontSize: '15px' }} />
          </Fab>
        )}

        {children}
      </Grid>
      <DialogContainer
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogContent={
          <Grid p={1} minWidth={'600px'}>
            {children}
          </Grid>
        }
        canselBtnText={'Закрыть'}
        maxWidth={'lg'}
        infiniteScrollId={infiniteScrollId}
      />
    </Grid>
  );
};
