import React, { FC, useCallback, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { CustomInput } from '../components/CustomInput';
import { DealItemStore } from '../../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from '../../../components/Bills/BillSummaryDelivery';
import { DealPositionUnitCostTypeEnum } from '../../../api/marketx';
import { AppDealUpdates } from '../../../slices/AppDeal';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { DealValidDaysControl } from './DealValidDaysControl';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import { DealSummaryTabHeader } from './DealSummaryTabHeader';

const numToStr = (x?: number | string | undefined): string => {
  if (typeof x === 'undefined' || x === 0 || x === '0') {
    return '';
  }
  return x.toString();
};

const CustomInputSuffix: any = styled(({ ...props }) => (
  <Typography variant="body2" fontWeight="500" color={'text.secondary'} {...props} />
))(() => ({
  paddingLeft: '4px',
}));

interface DealManualCostsTabProps {
  dealStore: DealItemStore;
  minHeightForTabSections: string;
}

export const DealManualCostsTab: FC<DealManualCostsTabProps> = observer(({ dealStore, minHeightForTabSections }): JSX.Element => {
  const profile = useAuthStore().profile;
  // Ручное расстояние доставки
  const [useManualDeliveryDistance, setUseManualDeliveryDistance] = useState(!!dealStore.deal?.useManualDeliveryDistance);
  const [manualDeliveryDistanceKm, setManualDeliveryDistanceKm] = useState(numToStr(dealStore.deal?.manualDeliveryDistanceKm));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleUseManualDeliveryDistanceOnChange = useCallback((): void => {
    setUseManualDeliveryDistance(checked => {
      const newValue = !checked;
      dealStore.updateDeal({ useManualDeliveryDistance: newValue }).catch(r => console.warn('handleUseManualDeliveryDistanceOnChange', r));
      return newValue;
    });
  }, [dealStore, setUseManualDeliveryDistance]);
  const handleManualDeliveryDistanceKmOnChange = useCallback(
    (value: string): void => {
      dealStore
        .updateDeal({ manualDeliveryDistanceKm: value ? parseFloat(value) || 0 : 0 })
        .catch(r => console.warn('handleManualDeliveryDistanceKmOnChange', r));
    },
    [dealStore]
  );
  const crossManualDeliveryDistanceKmOnChange = {
    handler: null,
    value: manualDeliveryDistanceKm,
    key: 'manualDeliveryDistanceKm',
  };
  crossManualDeliveryDistanceKmOnChange.handler = (prop: string, value: string): void => {
    crossManualDeliveryDistanceKmOnChange.value = value;
    setManualDeliveryDistanceKm(value);
  };

  // Ручная стоимость доставки
  const [useManualDeliveryCost, setUseManualDeliveryCost] = useState(!!dealStore.deal?.useManualDeliveryCost);
  const [manualDeliveryCost, setManualDeliveryCost] = useState(numToStr(dealStore.deal?.manualDeliveryCost));

  const handleUseManualDeliveryCostOnChange = (): void => {
    const newValue = !useManualDeliveryCost;
    setUseManualDeliveryCost(newValue);
    dealStore.updateDeal({ useManualDeliveryCost: newValue }).catch(r => console.warn('handleUseManualDeliveryCostOnChange', r));
  };

  const handleManualDeliveryCostOnChange = (value: string): void => {
    dealStore
      .updateDeal({ manualDeliveryCost: value ? parseFloat(value) || 0 : 0 })
      .catch(r => console.warn('handleManualDeliveryCostOnChange', r));
  };

  const crossManualDeliveryCostOnChange = {
    handler: null,
    value: manualDeliveryCost,
    key: 'manualDeliveryCost',
  };
  crossManualDeliveryCostOnChange.handler = (prop: string, value: string): void => {
    crossManualDeliveryCostOnChange.value = value;
    setManualDeliveryCost(value);
  };

  // Ручная стоимость услуг
  const [useManualServicesCost, setUseManualServicesCost] = useState(!!dealStore.deal?.useManualServicesCost);
  const [manualServicesCost, setManualServicesCost] = useState(numToStr(dealStore.deal?.manualServicesCost));
  const handleUseManualServicesCostOnChange = (): void => {
    const newValue = !useManualServicesCost;
    setUseManualServicesCost(newValue);
    dealStore.updateDeal({ useManualServicesCost: newValue }).catch(r => console.warn('handleUseManualServicesCostOnChange', r));
  };
  const handleManualServicesCostOnChange = (value: string): void => {
    dealStore
      .updateDeal({ manualServicesCost: value ? parseFloat(value) || 0 : 0 })
      .catch(r => console.warn('handleManualServicesCostOnChange', r));
  };
  const crossManualServicesCostOnChange = {
    handler: null,
    value: manualServicesCost,
    key: 'manualServicesCost',
  };
  crossManualServicesCostOnChange.handler = (prop: string, value: string): void => {
    crossManualServicesCostOnChange.value = value;
    setManualServicesCost(value);
  };

  // Ручное количество транспортных единиц
  const [useManualDeliveryUnits, setUseManualDeliveryUnits] = useState(!!dealStore.deal?.useManualDeliveryUnits);
  const [manualDeliveryUnitsCount, setManualDeliveryUnitsCount] = useState(numToStr(dealStore.deal?.manualDeliveryUnitsCount));

  const handleUseManualDeliveryUnitsOnChange = (): void => {
    const newValue = !useManualDeliveryUnits;
    setUseManualDeliveryUnits(newValue);
    dealStore.updateDeal({ useManualDeliveryUnits: newValue }).catch(r => console.warn('handleUseManualDeliveryUnitsOnChange', r));
  };
  const handleManualDeliveryUnitsCountCostOnChange = (value: string): void => {
    dealStore
      .updateDeal({ manualDeliveryUnitsCount: value ? parseFloat(value) || 0 : 0 })
      .catch(r => console.warn('handleManualDeliveryUnitsCountCostOnChange', r));
  };
  const crossManualDeliveryUnitsCountOnChange = {
    handler: null,
    value: manualDeliveryUnitsCount,
    key: 'manualDeliveryUnitsCount',
  };
  crossManualDeliveryUnitsCountOnChange.handler = (prop: string, value: string): void => {
    crossManualDeliveryUnitsCountOnChange.value = value;
    setManualDeliveryUnitsCount(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setIsTooltipOpen(true);
  };
  const handleTooltipClose = (): void => {
    setAnchorEl(null);
    setIsTooltipOpen(false);
  };

  const onOpenDialog = (isOpen: boolean): void => {
    setIsDialogOpen(isOpen);
  };

  // // Установка тендерной / голой цены как основной
  const handleSwitchChange = (isActive: boolean): void => {
    const changes: AppDealUpdates = {
      byCode: {},
    };
    if (isActive) {
      dealStore.deal.bareCostInputAttributes.changedPositions.forEach(pos => {
        changes.byCode[pos.code] = {
          code: pos.code,
          unitCostType: DealPositionUnitCostTypeEnum.Tender,
          ...(pos.unitCostType === DealPositionUnitCostTypeEnum.Bare && pos.fullUnitCost ? { tenderUnitCost: pos.fullUnitCost } : {}),
        };
      });
    } else {
      dealStore.deal.bareCostInputAttributes.changedPositions.forEach(pos => {
        changes.byCode[pos.code] = {
          code: pos.code,
          unitCostType: DealPositionUnitCostTypeEnum.Bare,
          ...(pos.unitCostType === DealPositionUnitCostTypeEnum.Tender && pos.calculatedBareUnitCost
            ? { bareUnitCost: pos.calculatedBareUnitCost }
            : {}),
        };
      });
    }
    dealStore.updatePositions(changes).catch(r => console.warn('handleSwitchChange', r));
  };
  return (
    <TabSelectWrapper onOpen={onOpenDialog}>
      <Grid item container minHeight={minHeightForTabSections}>
        <Grid item xs={12}>
          <DealSummaryTabHeader Icon={PanToolOutlinedIcon} dealItemStore={dealStore} isDialogOpen={isDialogOpen} />
          <Grid container item>
            <Grid container mr={'2px'}>
              <Grid container>
                <Grid item xs={6} md={8} container alignItems={'center'} flexWrap="nowrap">
                  <Checkbox
                    disabled={dealStore.isViewOnly()}
                    sx={{ padding: 0.5 }}
                    checked={useManualDeliveryDistance}
                    name={`useManualDeliveryDistance-${dealStore.dealCode}`}
                    onChange={handleUseManualDeliveryDistanceOnChange}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Расстояние доставки
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} container alignItems={'center'}>
                  <CustomInput
                    isViewOnly={dealStore.isViewOnly()}
                    color="#19B28D"
                    value={manualDeliveryDistanceKm}
                    crossChange={crossManualDeliveryDistanceKmOnChange}
                    onChange={handleManualDeliveryDistanceKmOnChange}
                    width="75px"
                    delay={500}
                  />
                  <CustomInputSuffix>км</CustomInputSuffix>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} md={8} container alignItems={'center'} flexWrap="nowrap">
                  <Checkbox
                    disabled={dealStore.isViewOnly()}
                    checked={useManualDeliveryCost}
                    sx={{ padding: 0.5 }}
                    name={`useManualDeliveryCost-${dealStore.dealCode}`}
                    onChange={handleUseManualDeliveryCostOnChange}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Стоимость доставки
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} container alignItems={'center'}>
                  <CustomInput
                    isViewOnly={dealStore.isViewOnly()}
                    color="#19B28D"
                    value={manualDeliveryCost}
                    crossChange={crossManualDeliveryCostOnChange}
                    onChange={handleManualDeliveryCostOnChange}
                    width="75px"
                    delay={500}
                  />
                  <CustomInputSuffix>₽</CustomInputSuffix>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} md={8} container alignItems={'center'} flexWrap="nowrap">
                  <Checkbox
                    disabled={dealStore.isViewOnly()}
                    checked={useManualDeliveryUnits}
                    sx={{ padding: 0.5 }}
                    name={`useManualDeliveryUnits-${dealStore.dealCode}`}
                    onChange={handleUseManualDeliveryUnitsOnChange}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Кол-во транспортных единиц
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} container alignItems={'center'}>
                  <CustomInput
                    isViewOnly={dealStore.isViewOnly()}
                    color="#19B28D"
                    value={manualDeliveryUnitsCount}
                    crossChange={crossManualDeliveryUnitsCountOnChange}
                    onChange={handleManualDeliveryUnitsCountCostOnChange}
                    width="75px"
                    delay={500}
                  />
                </Grid>
              </Grid>

              <Grid container mb={0.2}>
                <Grid item xs={6} md={8} container alignItems={'center'} flexWrap="nowrap">
                  <Checkbox
                    disabled={dealStore.isViewOnly()}
                    checked={useManualServicesCost}
                    sx={{ padding: 0.5 }}
                    name={`useManualServicesCost-${dealStore.dealCode}`}
                    onChange={handleUseManualServicesCostOnChange}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Стоимость услуг
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} container alignItems={'center'}>
                  <CustomInput
                    isViewOnly={dealStore.isViewOnly()}
                    color="#19B28D"
                    value={manualServicesCost}
                    crossChange={crossManualServicesCostOnChange}
                    onChange={handleManualServicesCostOnChange}
                    width="75px"
                    delay={500}
                  />
                  <CustomInputSuffix>₽</CustomInputSuffix>
                </Grid>
              </Grid>
              {dealStore.deal.mxBillStrategy && <DealValidDaysControl dealStore={dealStore} />}
              <Grid container alignItems={'center'} pl={1} pt={1}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={6} md={8} container alignItems={'center'} flexWrap="nowrap">
                    <Typography variant="body2" color={'text.secondary'}>
                      Задать цену с услугами для всех позиций
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4} container alignItems={'center'} pl={'10px'} onClick={handleTooltipOpen}>
                    <FormControlLabel
                      title={
                        !profile?.features?.canSetTenderCost
                          ? 'Недостаточно прав для задания цены с услугами'
                          : 'Задать цену с услугами для всех позиций'
                      }
                      control={
                        <IOSSwitch
                          disabled={
                            dealStore.isViewOnly() ||
                            dealStore.deal.bareCostInputAttributes.isSwitcherDisabled ||
                            !profile?.features?.canSetTenderCost
                          }
                          checked={
                            dealStore.deal.bareCostInputAttributes.isSwitcherDisabled
                              ? false
                              : dealStore.deal.bareCostInputAttributes.isSwitcherActive
                          }
                          onChange={() => handleSwitchChange(!dealStore.deal.bareCostInputAttributes.isSwitcherActive)}
                          sx={{
                            '& .MuiSwitch-track': !dealStore.deal.bareCostInputAttributes.isSwitcherDisabled &&
                              !dealStore.deal.bareCostInputAttributes.isSwitcherActive && {
                                backgroundColor: theme => (theme.palette.mode === 'light' ? '#f5f5f6' : '#4f4b4b'),
                                border: theme => (theme.palette.mode === 'light' ? '1px solid #d0c1c1' : '1px solid #656363'),
                              },
                          }}
                        />
                      }
                      label=""
                    />
                  </Grid>
                  <Popper
                    placement="bottom"
                    open={isTooltipOpen && !profile?.features?.canSetTenderCost}
                    anchorEl={anchorEl}
                    sx={{ zIndex: 1500 }}
                  >
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Paper>
                        <Box p={1}>
                          <Typography variant="subtitle2" fontWeight={500} component="span">
                            Недостаточно прав для задания цены с услугами
                          </Typography>
                        </Box>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabSelectWrapper>
  );
});
