import hash from 'object-hash';
import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { ErrorDocuments } from './Documents/types';
import {
  CatalogProductsFastSearchResponse,
  Freeze,
  FreezePosition,
  FreezesRequest,
  FreezesResponse,
  FreezeUpdates,
  FreezeUpdatesPosition,
} from '../api/marketx';
import { setClear } from '../utils/mobx';
import { WarehouseListStore } from './WarehouseListStore';
import { castInput, ValueStore, ValueStoreInputTypes, ValueStoreInputTypesType } from './ValueStore';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { SnackbarStore } from './SnackbarStore';
import { isOfflineError } from '../utils/network';
import { entityType, TopBarEntityStore } from './TopBarStore';
import { buildHash } from '../slices/AppDeal/lib';
import { IncomeMessage } from './Global/EventManager';
import { MsgType } from './Global/WebSocketStore';
import { AuthStore } from './AuthStore';

export interface AppFreezePositionUpdates extends FreezeUpdatesPosition {
  amount?: string;
  unitCode?: string;
}

export type AppFreezeUpdate = {
  warehouseCode?: string | null;
  freezeUntil?: string;
  positions?: FreezeUpdatesPosition[];
  byCode?: Record<string, AppFreezePositionUpdates>;
};

export type UpdateQueueItem = {
  createdAt: Date; // Время внесения изменения
  updatedAt: Date; // Время последнего изменения (если будет реализован мерж)
  hash: string; // хэш объекта изменений
  updates: AppFreezeUpdate;
  codes?: string[];
  properties: string;
  positionCode?: string;
  onResolve: (UpdateQueueItem) => void;
  onReject: (UpdateQueueItem) => void;
};

export class FreezeTopBarEntityStore implements TopBarEntityStore {
  freezeItemStore: FreezeItemStore = null;

  constructor(freezeItemStore: FreezeItemStore) {
    this.freezeItemStore = freezeItemStore;
    makeAutoObservable(this);
  }

  entityCode(): string {
    return this.freezeItemStore.freezeDocumentNumber;
  }

  titleForCatalog(): string {
    return 'Выберите товарные позиции для добавления во фриз';
  }

  titleForClient(): string {
    return 'Выберите клиента по фризу';
  }

  typeName(): entityType {
    return 'freeze';
  }

  customerTitle(): string {
    return '';
  }
}

export class FreezeItemStore {
  svc: FreezeItemService;
  rootStore: RootStore;
  authStore: AuthStore;
  apiStore?: ApiStore;
  snackbarStore: SnackbarStore;
  topBarEntityStore: FreezeTopBarEntityStore;
  public freezeDocumentNumber = '';
  public isLoading = false;
  public isLoaded = false;
  valuesStores = new Map<string, ValueStore>();
  item: Freeze;
  freezesMrcTotalCost = 0;
  freezesTotalCount = 0;
  freezesTotalWeight = 0;
  error: ErrorDocuments = null;
  ignoreBeforeDate?: Date;
  reloadRequired = false;
  loadedEpoch = 0;
  lastLoadedRequestTime?: Date;
  updatesQueue = new Array<UpdateQueueItem>();
  isSaving = false;
  freezeCodePositionsByProductCode: Record<string, FreezePosition>;
  positionsValuesStores = new WeakMap<FreezePosition, Map<string, ValueStore>>();
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.rootStore = rootStore;
    this.authStore = rootStore.getAuth();
    this.svc = new FreezeItemService();
    this.snackbarStore = rootStore.getSnackbar();
    this.handleWs = this.handleWs.bind(this);

    makeAutoObservable(this, {
      rootStore: false,
      svc: false,
      snackbarStore: false,
      apiStore: false,
      valuesStores: false,
      mapFreezePositionsByProductCode: false,
      authStore: false,
    });
  }

  public loadByCode(freezeCode?: string): void {
    if (!this.authStore.profile.resources?.some(i => i.uri === 'shop://entity/freeze/list')) {
      this.setErrorNotFound('Нет прав для просмотра фризов');
      return;
    }
    this.isLoading = true;
    this.freezeDocumentNumber = freezeCode;
    if (this.item?.code !== freezeCode) {
      this.item = observable(<Freeze>{ code: freezeCode });
    }
    this.svc.load(this, true);
  }

  setFreezeItemResult(data?: Freeze): void {
    if (data) {
      setClear(this.item, data);
    }
    this.freezeCodePositionsByProductCode = this.mapFreezePositionsByProductCode(this.item.positions || []);
  }

  setWithdraw(freezeProductCode?: string, withdrawQuantity?: number): void {
    this.apiStore
      .apiClientFreeze()
      .freezesWithdraw({
        freezeCode: this.item.code,
        freezeProductCode,
        withdrawQuantity,
      })
      .then(() => {
        this.reload();
      })
      .catch(e => {
        console.warn('setWithdrawRequest', e);
      });
  }

  setApprove(): void {
    this.apiStore
      .apiClientFreeze()
      .freezesApprove({
        code: this.item.code,
      })
      .then(() => {
        this.reload();
      })
      .catch(e => {
        console.warn('setApproveRequest', e);
      });
  }

  setResult(ctx: AxiosCallContext, res: FreezesResponse): void {
    this.lastLoadedRequestTime = ctx.startTime;
    this.setFreezeItemResult(res.freezes[0]);
    this.freezesMrcTotalCost = res.freezesMrcTotalCost || 0;
    this.freezesTotalCount = res.freezesTotalCount || 0;
    this.freezesTotalWeight = res.freezesTotalWeight || 0;
    this.isLoaded = true;
    this.isLoading = false;
    this.reloadRequired = false;
    this.loadedEpoch++;
  }
  isViewOnly(): boolean {
    const canSave = this.authStore.profile.resources?.some(i => i.uri === 'shop://entity/freeze/save');
    return !this.isLoaded || !this.item || !this.item.canUpdate || !canSave;
  }

  getWarehousesStore(): WarehouseListStore {
    if (!!this.item) {
      return this.rootStore.getLocalStoreFor(this, 'WarehouseListStore', WarehouseListStore, store => {
        store.loadListForFreeze(this.item);
      });
    } else {
      return { items: [] } as WarehouseListStore;
    }
  }

  setErrorNotFound(message?: string): void {
    runInAction(() => {
      this.error = {
        code: 400,
        message: message || 'Фриз не найден.',
        reason: 'not_found_document',
        hasNoAsses: !!message || undefined,
      };
      this.isLoaded = true;
      this.isLoading = false;
    });
  }
  filterDisplayField(key: string, value: any): any {
    if (key === 'validUntil' && value && value.indexOf('2000-') === 0) {
      // Не показываем дату окончания до 2000 года.
      // Это дефолтное значение, означает что дата не указана.
      return '';
    }
    return value;
  }
  getFreezeCommentValueStore(field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let fieldStore = this.valuesStores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: this.filterDisplayField(field, this.item.authorComment),
        onInputChangeDebounced: (value: string) => {
          this.apiStore
            .apiClientFreeze()
            .freezesSave({
              code: this.item.code,
              updates: {
                authorComment: castInput(value, type) as string,
              },
            })
            .catch(r => console.warn('handleCommentChange', r));
        },
      });
      this.valuesStores.set(field, fieldStore);
    }
    return fieldStore;
  }
  reload(isIdleRefresh = false): void {
    this.ignoreBeforeDate = new Date();
    this.svc.load(this, isIdleRefresh);
  }
  setEmpty(): void {
    this.isLoaded = true;
    this.reloadRequired = false;
    this.loadedEpoch++;
  }
  handleWs(msg: IncomeMessage): void {
    const msgFreezeCode = msg.data?.freezeCode;
    if (msg.msgType === MsgType.SHOP_FRONT_FREEZE_PRODUCT_ADDED && msgFreezeCode === this.freezeDocumentNumber) {
      this.reload();
      return;
    }
  }
  handleViewFreezeItemChange(): void {
    this.snackbarStore.showInfo(`Фриз обновлен до актуального состояния`);
  }
  mapFreezePositionsByProductCode(v: FreezePosition[]): Record<string, FreezePosition> {
    return (this.freezeCodePositionsByProductCode = v.reduce<Record<string, FreezePosition>>((acc, pos) => {
      acc[buildHash(pos.product.code)] = pos;
      return acc;
    }, {}));
  }
  async addPositionFromCatalog(productCode: string, quantity: number, frontCode: string): Promise<any> {
    const positionByProduct = this.freezeCodePositionsByProductCode[buildHash(productCode)];
    // @ts-ignore
    const updatesPosition: FreezeUpdatesPosition = {
      quantity: quantity || 1,
      isArchive: false,
      productCode: productCode,
    };

    if (positionByProduct) {
      updatesPosition.code = positionByProduct.code;
    } else {
      updatesPosition.code = frontCode;
    }
    return new Promise<void>((resolve, reject): void => {
      this.apiStore
        .apiClientFreeze()
        .freezesSave({
          code: this.item.code,
          updates: <FreezeUpdates>{
            positions: [updatesPosition],
          },
        })
        .then(res => {
          this.setFreezeItemResult(res.data.freeze);
          resolve();
        })
        .catch(r => reject(r));
    });
  }
  updateFreeze(updates: AppFreezeUpdate): Promise<UpdateQueueItem> {
    // кажется это проверка от дублей обновления
    const curHash = hash(updates);
    const prevItem = this.updatesQueue.length ? this.updatesQueue[this.updatesQueue.length - 1] : undefined;

    const properties = updates.byCode
      ? [Object.keys(updates.byCode), ...Object.keys(updates.byCode[Object.keys(updates.byCode)[0] || ''] || '')].join()
      : Object.keys(updates).join();
    const updItem = <UpdateQueueItem>{
      hash: curHash,
      properties,
      updates,
    };
    // тут можно попробовать склеить новое изменение с последним в очереди.
    const promise = new Promise<UpdateQueueItem>((resolve, reject) => {
      updItem.onResolve = resolve;
      updItem.onReject = reject;
    });
    if (prevItem && prevItem.properties === updItem.properties) {
      this.updatesQueue = this.updatesQueue.map(i => (i === prevItem ? updItem : i));
    } else {
      this.updatesQueue.push(updItem);
    }
    this.svc.debounceUpdate(this);
    return promise;
  }
  setReloadRequired(required: boolean): void {
    this.reloadRequired = required;
  }
  getTopBarEntityStore(): FreezeTopBarEntityStore {
    if (!this.topBarEntityStore) {
      this.topBarEntityStore = new FreezeTopBarEntityStore(this);
    }
    return this.topBarEntityStore;
  }

  getValueStoreByPos(position: FreezePosition, field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let stores = this.positionsValuesStores.get(position);
    if (!stores) {
      stores = new Map<string, ValueStore>();
      this.positionsValuesStores.set(position, stores);
    }
    let fieldStore = stores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: position[field],
        onInputChangeDebounced: value => {
          this.updateFreezePosition(position, { [field]: castInput(value, type) });
        },
      });
      stores.set(field, fieldStore);
    }
    return fieldStore;
  }

  updateFreezePosition(position: FreezePosition, updates: any): Promise<UpdateQueueItem> {
    const changes: AppFreezeUpdate = {
      byCode: {
        [position.code]: { ...updates, code: position.code },
      },
    };
    return this.updateFreeze(changes);
  }
  async fastSearhInCatalog(query: string): Promise<CatalogProductsFastSearchResponse> {
    if (query !== '') {
      return this.apiStore
        .apiClientCatalog()
        .catalogProductsFastSearch({
          query: query || '',
          warehouse: this.item.warehouseCode,
          branchOffice: this.item.branchOfficeCode,
          limit: 30,
          excludeServices: true,
        })
        .then((res): CatalogProductsFastSearchResponse => {
          return res.data;
        });
    } else {
      return Promise.resolve({ products: [], total: 0 });
    }
  }
}

class FreezeItemService {
  updateDebounceTimeout: NodeJS.Timeout;
  private idleInterval: NodeJS.Timeout;
  private updateDebounceTimeoutDelay = 600;
  private idleIntervalDelay = 1000 * 60 * 5;
  private offlineRequestDelay = 9000;
  debounceUpdate(store: FreezeItemStore, delay: number = undefined): void {
    if (this.idleInterval) {
      this.setupIdleInterval(store);
    }
    this.updateDebounceTimeout = setTimeout(() => {
      this.executeUpdate(store);
    }, delay || this.updateDebounceTimeoutDelay);
  }
  setupIdleInterval(store: FreezeItemStore): void {
    this.freezeIdleInterval();
    this.idleInterval = setInterval(() => store.reload(true), this.idleIntervalDelay);
  }
  freezeIdleInterval(): void {
    clearInterval(this.idleInterval);
    this.idleInterval = undefined;
  }

  executeUpdate(store: FreezeItemStore): void {
    if (store.isSaving) {
      // конкурирующий процесс?
      return;
    }
    if (!store.updatesQueue.length) {
      // нет изменений
      return;
    }
    if (this.idleInterval) {
      this.setupIdleInterval(store);
    }
    const upd: UpdateQueueItem = store.updatesQueue[0];
    runInAction(() => {
      store.isSaving = true;
    });
    const actionPromise: any[] = [];
    const positionsUpdates = Object.keys(upd.updates.byCode || {})?.map(key => {
      return { ...upd.updates.byCode[key] };
    });

    const promise = store.apiStore
      .apiClientFreeze()
      .freezesSave({
        code: store.item.code,
        updates: {
          warehouseCode: upd.updates.warehouseCode || undefined,
          freezeUntil: upd.updates.freezeUntil || undefined,
          positions: positionsUpdates.length ? positionsUpdates : undefined,
        },
      })
      .then(res => {
        // * если это последнее задание в очереди то актуализируем
        if (store.updatesQueue.filter(i => i !== upd).length === 0) {
          store.setFreezeItemResult(res.data?.freeze);
        }
      });
    actionPromise.push(promise);

    Promise.all(actionPromise)
      .then(() => {
        runInAction(() => {
          store.updatesQueue = store.updatesQueue.filter(q => q !== upd);
          store.isSaving = false;
        });
        upd.onResolve(upd);
        this.debounceUpdate(store, 1);
      })
      .catch(error => {
        // * обработка исключения возникшего во время выполнения задачи.
        runInAction(() => {
          store.isSaving = false;
        });
        if (isOfflineError(error)) {
          // * если ошибка сети, то повторяем запрос через заданный интервал, не очищая очередь
          clearTimeout(this.updateDebounceTimeout);
          this.debounceUpdate(store, this.offlineRequestDelay);
        } else {
          if (upd.onReject) {
            upd.onReject(upd);
          } else {
            console.warn(error, upd);
          }

          // * если ошибка в логике приложения то исключаем задачу из очереди и запускаем очередь со следующей задачи
          runInAction(() => {
            store.updatesQueue = store.updatesQueue.filter(q => q !== upd);
          });
          clearTimeout(this.updateDebounceTimeout);
          this.debounceUpdate(store, 1);
        }
        store.setReloadRequired(true);
      });
  }

  load(store: FreezeItemStore, isIdleRefresh: boolean): void {
    if (!store.freezeDocumentNumber) {
      store.setEmpty();
      return;
    }

    const requestTime = new Date();
    const idleInterval = this.idleInterval;

    runInAction(() => {
      store.isLoading = true;
      store.error = null;
    });

    store.apiStore
      .apiClientFreeze()
      .freezes(<FreezesRequest>{
        documentNumber: store.freezeDocumentNumber,
      })
      .then(res => {
        if (store.ignoreBeforeDate && requestTime.getTime() < store.ignoreBeforeDate.getTime()) {
          return;
        }
        if (store.lastLoadedRequestTime && requestTime.getTime() < store.lastLoadedRequestTime.getTime()) {
          return;
        }
        if (isIdleRefresh) {
          if (store.updatesQueue.length || idleInterval !== this.idleInterval) {
            return;
          }
        }
        store.setResult(getCallContext(res), res.data);
      })
      .catch(() => {
        store.setErrorNotFound();
      });
  }
}
