import { Box, Divider, Grid, Link, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { FC, useEffect, useRef, useState } from 'react';
import { BillItemStore } from 'src/store/Documents/BillItemStore';

type BillSummaryTabHeaderProps = {
  isDialogOpen: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  billItemStore: BillItemStore;
  dividerMb?: number;
};

export const BillSummaryTabHeader: FC<BillSummaryTabHeaderProps> = ({ billItemStore, Icon, isDialogOpen, dividerMb }) => {
  const [isInDialog, setIsInDialog] = useState(false);

  const parentRef = useRef(null);

  const mb = dividerMb ? dividerMb : 1;

  useEffect(() => {
    if (parentRef.current) {
      const hasClass = parentRef.current?.offsetParent?.classList.contains('MuiPaper-root');
      setIsInDialog(hasClass);
    }
  }, []);

  return (
    <Box ref={parentRef} sx={{ width: `${isInDialog ? '100%' : '0px'}` }}>
      {isDialogOpen && isInDialog && (
        <>
          <Grid container alignItems={'flex-start'} spacing={1}>
            <Grid item>
              <Icon sx={{ color: '#757575' }} />
            </Grid>
            <Grid item>
              <Link
                fontSize={'14px'}
                color="text.secondary"
                href={`/app/thread/bills/${encodeURIComponent(billItemStore.item?.documentNumber || billItemStore.billCode)}`}
                target="_blank"
              >
                Счет #{billItemStore.item?.documentNumber || billItemStore.billCode}
                {billItemStore.item?.documentDate && ` от ${formatDateSwaggerZ(billItemStore.item?.documentDate)}`}
              </Link>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', mb: mb }} />
        </>
      )}
    </Box>
  );
};
