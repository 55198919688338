import { RootStore } from './StoreManager';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { AuthStore } from './AuthStore';
import {
  BudgetGroup,
  CatalogProductReservesStatResponse,
  CatalogProductReservesV2Response,
  CatalogProductsFastSearchResponse,
  PaymentState,
  PaymentStatesResponse,
  ProductItemReserve,
  ProductReservesRequestReserveStateCodesEnum,
} from '../api/marketx';
import { setClear } from '../utils/mobx';
import { RouterStore } from './RouterStore';
import { WarehouseListStoreNew } from './WarehouseListStore';

export interface ReserveListRequest {
  productCode?: string;
  nomenclatureCodes?: string[];
  isForSoftReserve?: boolean;
  findHardReserves?: boolean;
  findSoftReserves?: boolean;
  sortByRank?: boolean;
  autoReserveEnabled?: boolean;
  warehouseCodes?: Array<string>;
  page?: number;
  countPerPage?: number;
  reserveDaysFrom?: number;
  reserveDaysTo?: number;
  awaitingDaysFrom?: number;
  awaitingDaysTo?: number;
  paymentStateCodes?: string[];
  reserveStateCodes?: string[];
  budgetGroupCodes?: string[];
  isExcludeServices?: boolean | null;

  branchOfficeCodes?: string[];

  departmentCodes?: string[];
  divisionCodes?: string[];
  employeeCodes?: string[];
  headCompanyCode?: string;
}

export class ReserveListStore {
  apiStore?: ApiStore;
  authStore: AuthStore;
  routerStore: RouterStore;
  warehouseListStore: WarehouseListStoreNew;
  request: ReserveListRequest = {
    page: 1,
    countPerPage: 15,
    autoReserveEnabled: false,
  };
  isLoading = false;
  isForSoftReserve = false;
  isMoreLoading = false;
  isLoaded = true;
  hasMore = true;
  hasAccessForSimplePage = false;
  totalCount;
  totalData: CatalogProductReservesStatResponse = {};
  reserves: ProductItemReserve[] = [];
  billPaymentStates: PaymentState[] = [];
  budgetGroupsStates: BudgetGroup[] = [];

  public isForReport = false;
  public routerControlEnabled = false;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.routerStore = rootStore.getRouter();
    this.warehouseListStore = new WarehouseListStoreNew(rootStore);

    this.hasAccessForSimplePage = this.authStore.canViewReserveByBranch;

    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  loadBillPaymentStates(): void {
    this.apiStore
      .apiClientPayment()
      .paymentStates()
      .then(res => {
        this.setBillPaymentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  setBillPaymentStates(data: PaymentStatesResponse): void {
    this.billPaymentStates = data.paymentStates || [];
  }
  setIsForReport(): void {
    this.isForReport = true;
  }

  loadBudgetGroupsStates(): void {
    this.apiStore
      .apiStatApi()
      .statReservesReferences()
      .then(res => runInAction(() => (this.budgetGroupsStates = res.data.budgetGroups)))
      .catch(e => console.warn('loadBudgetGroupsStates', e));
  }

  mergeRequestForReport(req: ReserveListRequest): void {
    setClear(this.request, req);
    this.request.page = 1;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.loadReserve();
  }

  mergeRequest(req: ReserveListRequest): void {
    set(this.request, req);
    this.request.page = 1;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.loadReserve();
  }

  loadWarehouseReportList(branchOfficeCode?: string): void {
    this.warehouseListStore.loadListWithWarehouseCode(branchOfficeCode, undefined, false, false);
  }

  loadWarehouseList(warehouseCode?: string): void {
    const chosenBranchOfficeCode = this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode;
    this.warehouseListStore.loadListWithWarehouseCode(chosenBranchOfficeCode, warehouseCode, false, false);
  }

  actualizeRouter(req: ReserveListRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();
    if (req.reserveDaysFrom) {
      params.set('reserveDaysFrom', `${req.reserveDaysFrom}`);
    }
    if (req.reserveDaysTo) {
      params.set('reserveDaysTo', `${req.reserveDaysTo}`);
    }
    if (req.awaitingDaysFrom) {
      params.set('awaitingDaysFrom', `${req.awaitingDaysFrom}`);
    }
    if (req.awaitingDaysTo) {
      params.set('awaitingDaysTo', `${req.awaitingDaysTo}`);
    }
    if (req.productCode) {
      params.set('productCode', req.productCode);
    }
    if (req.paymentStateCodes) {
      params.set('paymentStateCodes', `${req.paymentStateCodes.join(',')}`);
    }
    if (req.reserveStateCodes) {
      params.set('reserveStateCodes', `${req.reserveStateCodes.join(',')}`);
    }
    if (req.branchOfficeCodes) {
      params.set('branchOfficeCodes', `${req.branchOfficeCodes.join(',')}`);
    }
    if (req.nomenclatureCodes) {
      params.set('nomenclatureCodes', `${req.nomenclatureCodes.join(',')}`);
    }
    if (req.findHardReserves) {
      params.set('findHardReserves', `${req.findHardReserves}`);
    }
    if (req.findSoftReserves) {
      params.set('findSoftReserves', `${req.findSoftReserves}`);
    }
    if (req.sortByRank) {
      params.set('sortByRank', `${req.sortByRank}`);
    }
    if (req.autoReserveEnabled) {
      params.set('autoReserveEnabled', `${req.autoReserveEnabled}`);
    }
    if (req.warehouseCodes) {
      params.set('warehouseCodes', `${req.warehouseCodes.join(',')}`);
    }
    if (req.isExcludeServices) {
      params.set('isExcludeServices', `${req.isExcludeServices}`);
    }
    if (req.budgetGroupCodes) {
      params.set('budgetGroupCodes', `${req.budgetGroupCodes.join(',')}`);
    }

    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = `/app/${this.isForSoftReserve ? 'reserves-awaiting' : 'reserves'}`;
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }

  setRequestInit(req: ReserveListRequest): void {
    setClear(this.request, { ...this.request, ...req });
  }
  setSoftReserve(v = true): void {
    this.isForSoftReserve = v;
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  setCatalogProductReservesResult(data: CatalogProductReservesV2Response): void {
    this.totalCount = data.reservesTotalCount;
    this.hasMore = (this.reserves?.length || 0) < data.reservesTotalCount;
    if (data.reserves) {
      if (this.isMoreLoading) {
        this.reserves.push(...(data.reserves || []));
      } else {
        this.reserves = data.reserves;
      }
    } else {
      if (!this.isMoreLoading) {
        this.reserves = [];
      }
    }
    runInAction(() => {
      this.isMoreLoading = false;
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  loadReserve(): void {
    if (this.isForReport) {
      this.loadCatalogProductReserves();
      return;
    }
    if ((this.request.productCode && this.request.warehouseCodes?.length) || this.hasAccessForSimplePage) {
      this.loadCatalogProductReserves();
      return;
    } else {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  loadMoreReserve(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.request.page += 1;
    runInAction(() => {
      this.isMoreLoading = true;
    });
    if (this.isForReport) {
      this.loadCatalogProductReserves();
      return;
    }
    if ((this.request.productCode && this.request.warehouseCodes?.length) || this.hasAccessForSimplePage) {
      this.loadCatalogProductReserves();
      return;
    } else {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  loadCatalogProductReserves(): void {
    runInAction(() => {
      this.isLoading = true;
    });

    if (!this.isMoreLoading) {
      this.request.page = 1;
      this.totalCount = undefined;
      this.loadCatalogProductReservesStat();
    }

    const branchOfficeCode = this.request.branchOfficeCodes?.length
      ? this.request.branchOfficeCodes
      : this.hasAccessForSimplePage
      ? [this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode]
      : undefined;

    this.apiStore
      .apiClientCatalog()
      .catalogProductReservesV2({
        productCodes: this.request.productCode ? [this.request.productCode] : undefined,
        findHardReserves: !this.isForSoftReserve || undefined,
        findSoftReserves: true,
        sortByRank: this.isForSoftReserve ? true : undefined,
        page: this.request.page,
        count: this.request.countPerPage,
        warehouseCodes: this.request.warehouseCodes?.length ? this.request.warehouseCodes : undefined,
        autoReserveEnabled: !!this.request.autoReserveEnabled || undefined,
        reserveDaysFrom: this.request.reserveDaysFrom,
        reserveDaysTo: this.request.reserveDaysTo,
        awaitingDaysFrom: this.request.awaitingDaysFrom,
        awaitingDaysTo: this.request.awaitingDaysTo,
        paymentStateCodes: this.request.paymentStateCodes?.length ? this.request.paymentStateCodes : undefined,
        budgetGroupCodes: this.request.budgetGroupCodes?.length ? this.request.budgetGroupCodes : undefined,
        isExcludeServices: !!this.request.isExcludeServices || undefined,

        branchOfficeCodes: branchOfficeCode,
        departmentCodes: this.request.departmentCodes?.length ? this.request.departmentCodes : undefined,
        divisionCodes: this.request.divisionCodes?.length ? this.request.divisionCodes : undefined,
        employeeCodes: this.request.employeeCodes?.length ? this.request.employeeCodes : undefined,
        headCompanyCode: this.request.headCompanyCode || undefined,

        reserveStateCodes: this.request.reserveStateCodes?.length
          ? (this.request.reserveStateCodes as ProductReservesRequestReserveStateCodesEnum[])
          : undefined,
      })
      .then(res => {
        this.setCatalogProductReservesResult(res.data);
      })
      .catch(err => {
        console.warn('loadCatalogProductReservesRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  loadCatalogProductReservesStat(): void {
    const branchOfficeCode = this.request.branchOfficeCodes?.length
      ? this.request.branchOfficeCodes
      : this.hasAccessForSimplePage
      ? [this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode]
      : undefined;
    this.apiStore
      .apiClientCatalog()
      .catalogProductReservesStat({
        productCodes: this.request.productCode ? [this.request.productCode] : undefined,
        findHardReserves: !this.isForSoftReserve || undefined,
        findSoftReserves: true,
        sortByRank: this.isForSoftReserve ? true : undefined,
        warehouseCodes: this.request.warehouseCodes?.length ? this.request.warehouseCodes : undefined,
        autoReserveEnabled: !!this.request.autoReserveEnabled || undefined,
        reserveDaysFrom: this.request.reserveDaysFrom,
        reserveDaysTo: this.request.reserveDaysTo,
        awaitingDaysFrom: this.request.awaitingDaysFrom,
        awaitingDaysTo: this.request.awaitingDaysTo,
        paymentStateCodes: this.request.paymentStateCodes?.length ? this.request.paymentStateCodes : undefined,
        budgetGroupCodes: this.request.budgetGroupCodes?.length ? this.request.budgetGroupCodes : undefined,
        isExcludeServices: !!this.request.isExcludeServices || undefined,

        branchOfficeCodes: branchOfficeCode,
        departmentCodes: this.request.departmentCodes?.length ? this.request.departmentCodes : undefined,
        divisionCodes: this.request.divisionCodes?.length ? this.request.divisionCodes : undefined,
        employeeCodes: this.request.employeeCodes?.length ? this.request.employeeCodes : undefined,
        headCompanyCode: this.request.headCompanyCode || undefined,

        reserveStateCodes: this.request.reserveStateCodes?.length
          ? (this.request.reserveStateCodes as ProductReservesRequestReserveStateCodesEnum[])
          : undefined,
      })
      .then(res => {
        this.setCatalogProductReservesStat(res.data);
      })
      .catch(err => {
        console.warn('catalogProductReservesStatRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  setCatalogProductReservesStat(data: CatalogProductReservesStatResponse): void {
    this.totalData = data;
  }

  async fastSearchInCatalog(query: string): Promise<CatalogProductsFastSearchResponse> {
    if (query !== '') {
      const branchOfficeCodeAuth = this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
      return this.apiStore
        .apiClientCatalog()
        .catalogProductsFastSearch({
          query: query || '',
          warehouse: this.request.warehouseCodes?.[0], // отсутствует
          branchOffice: branchOfficeCodeAuth,
          limit: 30,
          // stockRequired,
        })
        .then((res): CatalogProductsFastSearchResponse => {
          return res.data;
        });
    } else {
      return Promise.resolve({ products: [], total: 0 });
    }
  }
}
