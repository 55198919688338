import { NextRouter, useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { ApplicationErrorEvent } from '../store/ErrorStore';
import { observer } from 'mobx-react';
import { useRootStore } from '../store/MobxStoreProvider';
import { Landing } from 'src/components/Landing/Landing';
import { SessionContextValue, useSession } from 'next-auth/react';

const publicRoutes = {
  '/': true,
  '/app/tools/whmap': true,
};

const isProtectedRoute = (router: NextRouter): boolean => !publicRoutes[router.route];

interface AuthorizationGuardProps {
  children: React.ReactNode;
  err?: any;
  isAuthError?: boolean;
}

// Управляет доступом к настоящим страницам или заглушке-лэндинг и выполняет редиректы после авторизации.
const AuthorizationGuard: FC<AuthorizationGuardProps> = observer(({ children, isAuthError }): any => {
  const session: SessionContextValue = useSession();
  const router = useRouter();
  const rootStore = useRootStore();
  const authStore = rootStore.getAuth();
  rootStore.getError().setErrorHandler((e: ApplicationErrorEvent): void => {
    rootStore.getSnackbar().showError(`${e.message}`);
  });

  const hasAccess = authStore.hasFrontOfficeAccess();

  const showChildren =
    hasAccess || // авторизованный сотрудник с доступом
    !isProtectedRoute(router); // общедоступная страница

  useEffect(() => {
    session.update();
  }, []);
  useEffect(() => {
    rootStore.getAuth().setSession(session);
  }, [rootStore, session?.data?.accessToken, session?.status, session?.data?.error]);
  // первый authStore.isLoaded===false на бэке, по этому лэндинг не рендериться
  if ((!authStore.isAuthenticated && authStore.isInitialized) || !authStore.isLoaded) {
    return <Landing isAuthError={isAuthError} />;
  } else if (router.route === '/' && hasAccess) {
    const redirectUri = authStore.profile?.features?.homePage ?? '/app/home';
    router.push(redirectUri).catch(() => {
      rootStore.getError().pushAppError(new Error('Не удалось выполнить редирект ' + redirectUri));
    });
    return <Landing redirectUri={redirectUri} isAuthError={isAuthError} />;
  } else if (showChildren) {
    return <>{children}</>;
  }
  return <></>;
});

export default AuthorizationGuard;
