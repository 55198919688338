import React, { FC, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { DealApprovalBlockReason, DocumentNotice, DocumentNoticeTypeEnum } from 'src/api/marketx';
import { ChipTitleStyled } from '../../../views/deals/DealPosition/DealPositionPrices';
import { Paper, Popper } from '@mui/material';

function modifyNotices(message: string): string | (string | React.JSX.Element)[] {
  return message.includes('#')
    ? message.split(' ').map((item, index) => {
        if (item[0] === '#') {
          return <ChipTitleStyled key={item} label={item} />;
        }
        return index === 0 ? item + ' ' : ' ' + item + ' ';
      })
    : message;
}

export const IconWarningForApproval: FC<{ submitNotices: DocumentNotice[]; currentBlockReasons?: DealApprovalBlockReason[] }> = ({
  submitNotices = [],
  currentBlockReasons = [],
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setIsTooltipOpen(true);
  };
  const handleTooltipClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsTooltipOpen(false);
  };
  return (
    <>
      <Box onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
        <IconButton size="large" sx={{ pointerEvents: 'none', cursor: 'default' }} color="primary" component="span">
          <Badge color="primary" badgeContent={submitNotices?.length + currentBlockReasons?.length}>
            {submitNotices.length > 0 && submitNotices.filter(i => i.type === DocumentNoticeTypeEnum.Error).length > 0 ? (
              <ErrorIcon color="error" fontSize="inherit" />
            ) : (
              <WarningIcon color="warning" fontSize="inherit" />
            )}
          </Badge>
        </IconButton>
        <Popper placement="bottom" open={isTooltipOpen} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
          <Paper className="withCustomScroll" sx={{ display: 'grid', maxHeight: '250px', p: 1, maxWidth: '650px' }}>
            <Box p={1}>
              {submitNotices.map((notice, index) => {
                const textValue = modifyNotices(notice.message);
                return (
                  <Box key={notice.code + index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      {notice.type === DocumentNoticeTypeEnum.Error ? <ErrorIcon color="error" /> : <WarningIcon color="warning" />}
                    </ListItemIcon>
                    {textValue && <ListItemText>{textValue}</ListItemText>}
                  </Box>
                );
              })}
              {currentBlockReasons.map((blockReasons, index) => {
                const textValue = modifyNotices(blockReasons.message);
                return (
                  <Box key={blockReasons.code + index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <WarningIcon color="warning" />
                    </ListItemIcon>
                    {blockReasons.message && <ListItemText>{textValue}</ListItemText>}
                  </Box>
                );
              })}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </>
  );
};
