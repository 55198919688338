import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TooltipForReserveList } from '../../../components/Product/Reserve/TooltipForReseveList';
import { ContractCostPosition } from '../../../api/marketx';
import { Box } from '@mui/material';
import { TooltipForReserveListHeader } from 'src/components/Product/Reserve/TooltipForReserveListHeader';

interface DealPositionAdditionalTabReservesProps {
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
  branchOfficeCode: string;
  documentNumber?: string;
  isDialogOpen?: boolean;
}

export const DealPositionAdditionalTabReserves: FC<DealPositionAdditionalTabReservesProps> = observer(
  ({ dealPosition, branchOfficeCode, documentNumber, agreementPosition, isDialogOpen }): JSX.Element => {
    const [isInDialog, setIsInDialog] = useState(false);

    const parentRef = useRef(null);

    useEffect(() => {
      if (parentRef.current) {
        const hasClass = parentRef.current?.offsetParent?.classList.contains('MuiPaper-root');

        setIsInDialog(hasClass);
      }
    }, []);

    return (
      <>
        <Box ref={parentRef} sx={{ width: `${isInDialog ? '100%' : '0px'}`, p: `${isInDialog ? '8px 0 0 8px' : 0}` }}>
          {isDialogOpen && isInDialog && (
            <TooltipForReserveListHeader
              isForSoftReserve={false}
              product={dealPosition?.product || agreementPosition?.product}
              warehouseCodes={dealPosition?.warehouseCode ? [dealPosition.warehouseCode] : agreementPosition ? undefined : undefined}
            />
          )}
        </Box>
        <Grid sx={{ height: '100%' }} position={'relative'}>
          {dealPosition?.product?.hasReserves || !!agreementPosition ? (
            <TooltipForReserveList
              product={dealPosition?.product || agreementPosition?.product}
              documentNumber={documentNumber}
              warehouseCodes={dealPosition?.warehouseCode ? [dealPosition.warehouseCode] : agreementPosition ? undefined : undefined}
              mustRender={dealPosition?.warehouseCode}
              branchOfficeCode={branchOfficeCode}
              isDialogOpen={isDialogOpen}
              isFromProductTab={true}
            />
          ) : (
            <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
              Резервов нет
            </Typography>
          )}
        </Grid>
      </>
    );
  }
);
