import { Divider, Grid, Link, Typography } from '@mui/material';
import { FC } from 'react';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { TableRowTitle } from 'src/components/Catalog/Table/CatalogTableRowVirtualizeItemView';
import { Product, ProductReservesRequestReserveStateCodesEnum } from 'src/api/marketx';

type TooltipForReserveListHeaderProps = {
  isForSoftReserve?: boolean;
  product?: Product;
  warehouseCode?: string;
  warehouseCodes?: string[];
  reserveStateCodes?: ProductReservesRequestReserveStateCodesEnum[];
  autoReserveEnabled?: boolean;
};

export const TooltipForReserveListHeader: FC<TooltipForReserveListHeaderProps> = props => {
  const { isForSoftReserve, product, warehouseCode, warehouseCodes, autoReserveEnabled, reserveStateCodes } = props;

  return (
    <>
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item>
          {isForSoftReserve ? <DynamicFeedOutlinedIcon sx={{ color: '#757575' }} /> : <LockPersonRoundedIcon sx={{ color: '#757575' }} />}
        </Grid>
        <Grid item>
          <Typography fontSize={'12px'} sx={{ pl: '2px' }} color="text.secondary">
            <TableRowTitle item={product} />
          </Typography>
        </Grid>
        <Grid item>
          {isForSoftReserve ? (
            <Link
              href={`/app/reserves-awaiting?productCode=${product.code}${
                warehouseCode ? '&warehouseCodes=' + warehouseCode : warehouseCodes?.length ? '&warehouseCodes=' + warehouseCodes[0] : ''
              }${autoReserveEnabled ? '&autoReserveEnabled=true' : ''}${
                reserveStateCodes?.length ? `&reserveStateCodes=${reserveStateCodes.join(',')}` : ''
              }`}
              sx={{ textTransform: 'uppercase', fontSize: '14px' }}
              underline="none"
              target="_blank"
            >
              {'К ожиданиям резерва >>'}
            </Link>
          ) : (
            <Link
              href={`/app/reserves?productCode=${product.code}${
                warehouseCode ? '&warehouseCodes=' + warehouseCode : warehouseCodes?.length ? '&warehouseCodes=' + warehouseCodes[0] : ''
              }${reserveStateCodes?.length ? `&reserveStateCodes=${reserveStateCodes.join(',')}` : ''}`}
              sx={{ textTransform: 'uppercase', fontSize: '14px' }}
              underline="none"
              target="_blank"
            >
              {'К резервам >>'}
            </Link>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mb: 1 }} />
    </>
  );
};
