import React, { useState, FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import ShipmentPriceChart from '../../../components/charts/ShipmentPriceChart';
import ChangingValueTypography from './ChangingValueTypography';
import { AppDealPosition } from '../../../slices/AppDeal';
import { formatPrice } from '@mx-ui/helpers';
import { getRusDate } from '../lib';
import { observer } from 'mobx-react-lite';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BarCharts from '../../../components/charts/BarCharts';
import { CustomSelectNew } from '../components/CustomSelectNew';
import { ContractCostPosition } from '../../../api/marketx';
import { Popover } from '@mui/material';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { Link as MxLink } from '@mx-ui/ui';
import BarChartIcon from '@mui/icons-material/BarChart';
import { DealPositionAdditionalTabHeader } from './DealPositionAdditionalTabHeader';

interface DealPositionAdditionalChartsProps {
  position: AppDealPosition | ContractCostPosition;
}
export const DealPositionAdditionalCrarts: FC<DealPositionAdditionalChartsProps> = observer(({ position }): JSX.Element => {
  const chartsDataStore = useRootStore().getChartsDataStore();
  const auth = useAuthStore();
  const previous = 'previous' in position ? position?.previous : undefined;

  const plotShipmentAmount = useMemo(
    () => chartsDataStore && position.productCode && chartsDataStore.getPlot(position.productCode, 'shipment_amount'),
    [chartsDataStore.plots, position.productCode, auth?.profile?.chosenBranchOfficeCode]
  );
  const plotShipmentPrice = useMemo(
    () => chartsDataStore && position.productCode && chartsDataStore.getPlot(position.productCode, 'shipment_price'),
    [chartsDataStore.plots, position.productCode, auth?.profile?.chosenBranchOfficeCode]
  );
  const plotProductMrcList = useMemo(
    () => chartsDataStore && position.productCode && chartsDataStore.getPlot(position.productCode, 'product_mrc_list'),
    [chartsDataStore.plots, position.productCode, auth?.profile?.chosenBranchOfficeCode]
  );

  const plotCompetitors = useMemo(() => {
    if (chartsDataStore && position.productCode) {
      return chartsDataStore.getCompetitorsPlot(position.productCode, ['product_competitors_cost_avg', 'product_competitors_cost']);
    }
  }, [chartsDataStore.plots, position.productCode, auth?.profile?.chosenBranchOfficeCode]);

  const [lastPurchasePopperAnchor, setLastPurchasePopperAnchor] = useState(null);
  const [lastPurchasePopperOpen, setLastPurchasePopperOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleChangeCompetitor = (value: string): void => {
    chartsDataStore.loadCompetitorsPlot(position.productCode, value);
  };

  const onOpenDialog = (isOpen: boolean): void => {
    setIsDialogOpen(isOpen);
  };

  return (
    <TabSelectWrapper onOpen={onOpenDialog}>
      <Grid className="withCustomScroll" container sx={{ height: '100%' }}>
        <DealPositionAdditionalTabHeader Icon={BarChartIcon} isDialogOpen={isDialogOpen} position={position} />
        {previous?.dealCode && (
          <>
            <Grid container sx={{ marginBottom: 1 }} alignItems={'center'}>
              <Box>
                <Typography variant="caption" fontWeight={500}>
                  Товар приобретался ранее
                </Typography>
                <IconButton
                  onClick={e => {
                    setLastPurchasePopperAnchor(e.currentTarget);
                    setLastPurchasePopperOpen(!lastPurchasePopperOpen);
                  }}
                  size="small"
                  style={{ padding: '0px', marginTop: '-4px' }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>

              <Typography variant="caption" fontWeight={500}>
                {'по цене ' + previous.unitCost + ' ₽ за 1 ' + previous.unit}
              </Typography>
            </Grid>

            <Popover
              open={lastPurchasePopperOpen}
              anchorEl={lastPurchasePopperAnchor}
              onClose={() => setLastPurchasePopperOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container spacing={1} width={'350px'} p={1}>
                <Grid item xs={12}>
                  <Typography color="text.secondary">
                    <MxLink href={'/app/deals/' + previous.dealCode} variant="inherit" color="textPrimary">
                      {previous.dealCode}
                    </MxLink>
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Дата заявки
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {getRusDate(previous.dealCreatedAt)}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Количество
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {previous.quantity} {previous.unit}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Цена товара
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {formatPrice(Math.abs(previous.unitCost))} ₽
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Цена с услугами
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {formatPrice(Math.abs(previous.fullUnitCost))} ₽
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Итоговая стоимость
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {formatPrice(Math.abs(previous.fullTotalCost))} ₽
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption" color={'text.secondary'}>
                    Маржинальность
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="caption" color={'text.secondary'}>
                    {formatPrice(Math.abs(previous.managerFullCostMarginPct))}% ({formatPrice(Math.abs(previous.managerFullCostMargin))} ₽)
                  </Typography>
                </Grid>
              </Grid>
            </Popover>
          </>
        )}

        <Grid container mb={'10px'} pt={'10px'} position={'relative'}>
          {!chartsDataStore.isLoaded && (
            <Grid sx={{ position: 'absolute', top: '0', width: '100%' }}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={12} sx={{ border: '1px solid #B9B9BA', padding: '4px', borderRadius: '5px' }} mb={2}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="text.primary">
                      Цены рынка, за 10 дней
                    </Typography>
                  </Grid>

                  <ChangingValueTypography plot={plotCompetitors[0]} isMiddleVale={true} middleBetweenFirstAndLast={true} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <BarCharts plot={plotCompetitors[0]} height={80} isPrice={true} />
              </Grid>
              <Grid item xs={12} p={1}>
                <Grid maxWidth={'300px'}>
                  {chartsDataStore?.competitors ? (
                    <CustomSelectNew
                      label="Выбрать конкурента"
                      isViewOnly={!chartsDataStore.isLoaded || chartsDataStore.isLoading}
                      onChange={handleChangeCompetitor}
                      options={chartsDataStore.competitors}
                    />
                  ) : (
                    <Typography sx={{ flexShrink: 0, mr: 2, mb: '2px' }} fontWeight={500}>
                      Нет информации о конкурентах
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container flexWrap="wrap" position={'relative'} pt={'10px'}>
                <Grid item xs={12} md={6}>
                  <ChangingValueTypography plot={plotCompetitors[1]} isMiddleVale={true} middleBetweenFirstAndLast={true} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BarCharts plot={plotCompetitors[1]} height={100} isPrice={true} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.primary">
                  Цены МРЦ
                </Typography>
              </Grid>

              <ChangingValueTypography plot={plotProductMrcList} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <ShipmentPriceChart plot={plotProductMrcList} height={80} isPrice={true} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.primary">
                  Цены отгрузок, за 10 дней
                </Typography>
              </Grid>
              <ChangingValueTypography plot={plotShipmentPrice} isMiddleVale={true} middleBetweenFirstAndLast={true} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <BarCharts plot={plotShipmentPrice} height={80} isPrice={true} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.primary">
                  Отгрузок в день, за 10 дней
                </Typography>
              </Grid>

              <ChangingValueTypography plot={plotShipmentAmount} isMiddleVale={true} middleBetweenFirstAndLast={true} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <BarCharts plot={plotShipmentAmount} height={80} />
          </Grid>
        </Grid>
      </Grid>
    </TabSelectWrapper>
  );
});
