import { Box, Divider, Grid, Link, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { FC, useEffect, useRef, useState } from 'react';
import { DealItemStore } from 'src/store/DealItemStore';

type DealSummaryTabHeaderProps = {
  isDialogOpen: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  dealItemStore: DealItemStore;
  dividerMb?: number;
};

export const DealSummaryTabHeader: FC<DealSummaryTabHeaderProps> = ({ dealItemStore, Icon, isDialogOpen, dividerMb }) => {
  const [isInDialog, setIsInDialog] = useState(false);

  const parentRef = useRef(null);

  const mb = dividerMb ? dividerMb : 1;

  useEffect(() => {
    if (parentRef.current) {
      const hasClass = parentRef.current?.offsetParent?.classList.contains('MuiPaper-root');
      setIsInDialog(hasClass);
    }
  }, []);

  return (
    <Box ref={parentRef} sx={{ width: `${isInDialog ? '100%' : '0px'}` }}>
      {isDialogOpen && isInDialog && (
        <>
          <Grid container alignItems={'flex-start'} spacing={1}>
            <Grid item>
              <Icon sx={{ color: '#757575' }} />
            </Grid>
            <Grid item>
              <Link
                fontSize={'14px'}
                color="text.secondary"
                href={`/app/thread/deals/${encodeURIComponent(dealItemStore.dealCode)}`}
                target="_blank"
              >
                Заявка #{dealItemStore.dealCode}{' '}
                {dealItemStore.deal?.createdAt && `от ${formatDateSwaggerZ(dealItemStore.deal?.createdAt)}`}
              </Link>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', mb: mb }} />
        </>
      )}
    </Box>
  );
};
