import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ApprovalHistoryStore } from '../../../store/ApprovalHistoryStore';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { formatDateSwaggerZ, formatPriceCur, formatYmdHiLZ } from '@mx-ui/helpers';
import { AppUserGroupCodes } from '../../../types/AppUserGroup';
import { blueGrey } from '@mui/material/colors';
import FactCheck from '@mui/icons-material/FactCheck';
import { useWebSocketHandler } from '../../../hooks/useWebSocketHandler';
import { ChipTitleStyled } from '../../../views/deals/DealPosition/DealPositionPrices';
import { Paper, Popper } from '@mui/material';
import { UserAvatar } from '@mx-ui/ui';
import { BillActionLog } from 'src/api/marketx';
interface ApproveHistoryWithUpdateLogsDropDownProps {
  approvalStore: ApprovalHistoryStore;
  immediatelyOnStart?: boolean;
}

const actionCodeByColor = {
  DealApprovalApproveAction: 'green',
  DealApprovalDeclineAction: 'red',
  DealApprovalAskWithdraw: '#ec8344', // yellow
  DealUpdateStateAction: '#ec8344', // yellow
};
export const groupsIcons = {
  [AppUserGroupCodes.DIVISION_CHIEF]: {
    code: 'Д',
    title: 'Дивизион',
  },
  [AppUserGroupCodes.COMPANY_CHIEF]: {
    code: 'К',
    title: 'Компания',
  },
  [AppUserGroupCodes.SELLER]: {
    code: 'М',
    title: 'Менеджер',
  },
  [AppUserGroupCodes.SELLER_CHIEF]: {
    code: 'О',
    title: 'Офис',
  },
  [AppUserGroupCodes.OFFICE_CHIEF]: {
    code: 'Ф',
    title: 'Филиал',
  },
};
export const sxAvatar = {
  backgroundColor: 'transparent',
  color: theme => (theme.palette.mode === 'dark' ? blueGrey[200] : blueGrey[900]),
  fontSize: '14px',
  border: theme => (theme.palette.mode === 'dark' ? `1px solid ${blueGrey[200]}` : `1px solid ${blueGrey[900]}`),
  width: 24,
  height: 24,
};

export const ApproveHistoryWithUpdateLogsDropDown: FC<ApproveHistoryWithUpdateLogsDropDownProps> = observer(
  ({ approvalStore, immediatelyOnStart }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      setAnchorEl(event.target);
      setIsTooltipOpen(true);
    };
    const handleTooltipClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      setAnchorEl(null);
      setIsTooltipOpen(false);
    };
    useEffect(() => {
      approvalStore.reload(immediatelyOnStart);
    }, [immediatelyOnStart]);
    useWebSocketHandler(approvalStore.handleWs, [approvalStore]);
    const badgeCounter = useMemo(() => {
      let result = 0;
      if (approvalStore.historyWithUpdate?.length > 0) {
        approvalStore.historyWithUpdate?.forEach(approval => {
          result += 1 + (approval.dealUpdateLogChanges?.length || 0);
        });
      }
      return result;
    }, [approvalStore.historyWithUpdate?.length]);

    const titleEl = useMemo(() => {
      return (
        <Grid p={1} className="withCustomScroll" sx={{ display: 'grid', maxHeight: '250px' }}>
          {/* // <Grid p={1} className="withCustomScroll" sx={{ display: 'grid', gridTemplateColumns: 'minmax(350px, 750px)', maxHeight: '250px' }}> */}
          {approvalStore.isLoaded ? (
            !!approvalStore.historyWithUpdate?.length ? (
              <>
                {approvalStore.historyWithUpdate?.map((log, index) => {
                  return (
                    <Grid key={index} container mb={0.5}>
                      {index === 0 ||
                      approvalStore.historyWithUpdate[index]?.employee?.code !==
                        approvalStore.historyWithUpdate[index - 1]?.employee?.code ? (
                        <Grid item xs={12}>
                          <Grid container alignItems="center" mb={0.5}>
                            <Grid item>
                              <Avatar sx={sxAvatar} title={groupsIcons?.[log.userGroupCode]?.title || '-'}>
                                {groupsIcons?.[log.userGroupCode]?.code || '-'}
                              </Avatar>
                            </Grid>
                            <Grid item ml={1}>
                              <Typography variant="body2">
                                {log.employee?.surname} {log.employee?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                      {log?.dealUpdateLogChanges.length
                        ? log?.dealUpdateLogChanges.map((log, index) => {
                            const changeValue = log.changeValue.split('>');
                            return (
                              <Grid item xs={12} key={index}>
                                <Grid container flexWrap={'nowrap'}>
                                  <Grid item flex="0 0 112px" pt={0.4}>
                                    <Typography variant="body2">{formatYmdHiLZ(log.createdAt)}</Typography>
                                  </Grid>

                                  <Grid item ml={1}>
                                    <Grid container alignItems="flex-start">
                                      <Grid item>
                                        {log.changedEntity && (
                                          <ChipTitleStyled
                                            label={log.changedEntity}
                                            sx={{ backgroundColor: '#AFDEEF', color: '#465960' }}
                                          />
                                        )}
                                        <Typography variant="body2" component={'span'}>
                                          {log.changeEntityProperty} &nbsp;
                                        </Typography>
                                      </Grid>

                                      <Grid item>
                                        <Typography variant="body2" component={'span'}>
                                          {changeValue.length === 2 ? (
                                            <Typography variant="body2" component={'span'}>
                                              {`${changeValue[0]} `}
                                              <Typography variant="body2" component={'span'} fontWeight={'bold'} color={'#5CBADE'}>
                                                {'>'}
                                              </Typography>
                                              {` ${changeValue[1]}`}
                                            </Typography>
                                          ) : (
                                            changeValue[0]
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })
                        : null}

                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <Typography variant="body2" component={'span'}>
                                  {formatYmdHiLZ(log.when)}
                                </Typography>
                              </Grid>
                              <Grid item ml={1}>
                                <Typography
                                  variant="body2"
                                  component={'span'}
                                  sx={{ color: actionCodeByColor[log?.actionCode] ?? undefined }}
                                >
                                  {log.actionTitle || `Перевел в статус: ${log.newStateTitle}`}
                                  {log.actionCode === 'DealUpdateStateAction' && ':'}&nbsp;
                                </Typography>
                              </Grid>
                              {log.actionCode === 'DealUpdateStateAction' && (
                                <Grid item>
                                  <Typography variant="body2" component={'span'}>
                                    {log.oldStateTitle}
                                  </Typography>
                                  <Typography variant="body2" component={'span'} fontWeight={'bold'} color={'#5CBADE'}>
                                    {' > '}
                                  </Typography>
                                  <Typography variant="body2" component={'span'}>
                                    {log.newStateTitle}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item textAlign="right">
                            <Typography variant="body2">{log.comment}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {approvalStore.historyWithUpdate[index + 1] &&
                      approvalStore.historyWithUpdate[index + 1]?.employee?.code !==
                        approvalStore.historyWithUpdate[index]?.employee?.code ? (
                        <Grid item xs={12} mb={0.5}>
                          <Divider />
                        </Grid>
                      ) : null}
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Box width={'100%'} textAlign="center">
                <Typography variant="body2">Нет данных</Typography>
              </Box>
            )
          ) : (
            <Skeleton width={100} />
          )}
        </Grid>
      );
    }, [approvalStore.loadedEpoch, approvalStore.updateDealLogs]);

    return (
      <Box title="История согласований" onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
        <Box p={1.5} display="flex">
          <Badge color="primary" badgeContent={badgeCounter}>
            <FactCheck fontSize="medium" />
          </Badge>
          <Popper placement="top" open={isTooltipOpen} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
            <Paper sx={{ p: 1, maxWidth: '650px' }}>{titleEl}</Paper>
          </Popper>
        </Box>
      </Box>
    );
  }
);
// StateUpdate: BillActionLogType{
// 	Code:  "state_update",
// 	Title: "Изменение статуса",
// },
// ReservationWithdraw: BillActionLogType{
// 	Code:        "reservation_withdraw",
// 	Title:       "Снятие резерва",
// 	Description: "Снятие резерва по счёту или по позиции в счёте",
// },
// AutoReserveSettingUpdate: BillActionLogType{
// 	Code:        "auto_reserve_setting_update",
// 	Title:       "Переключение флага авто-резервирования",
// 	Description: "Переключение флага авто-резервирования по счёту или по позиции в счёте",
// },
// SoftReservePrioritize: BillActionLogType{
// 	Code:        "soft_reserve_prioritize",
// 	Title:       "Приоритетное резервирование",
// 	Description: "Назначено приоритетное резервирование",
// },
// DealApprovalDecline: BillActionLogType{
// 	Code:  "deal_approval_decline",
// 	Title: "Согласование заявки отозвано",
// },
// DealApprovalApproved: BillActionLogType{
// 	Code:  "deal_approved_approval",
// 	Title: "Заявка повторно согласована",
// },
// SubordinateDocsIncome: BillActionLogType{
// 	Code:  "subordinate_docs_income",
// 	Title: "Получение подчиненных документов",
// },
// Reconciliation: BillActionLogType{
// 	Code:        "reconciliation",
// 	Title:       "Фактурирование",
// 	Description: "Фактурирование по счёту или по позиции в счёте",
// },
// Prolongation: BillActionLogType{
// 	Code:  "prolongation",
// 	Title: "Пролонгация срока действия",
// },
// HasSubordinateDocFlagRemove: BillActionLogType{
// 	Code:  "has_subordinate_doc_flag_remove",
// 	Title: `Снятие флага "Есть подчиненные документы"`,
// },

const actionCodeByColorBills = {
  deal_approved_approval: 'green',
  deal_approval_decline: 'red',
  state_update: '#ec8344', // оранж
  // DealApprovalAskWithdraw: '#ffea00', // yellow
};
export const HistoryLogsDropDownBills: FC<{ actionLogs: BillActionLog[] }> = observer(({ actionLogs = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setIsTooltipOpen(true);
  };
  const handleTooltipClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsTooltipOpen(false);
  };
  const badgeCounter = useMemo(() => {
    return actionLogs?.length || 0;
  }, [actionLogs]);

  const titleEl = useMemo(() => {
    return (
      <Grid p={1} className="withCustomScroll" sx={{ display: 'grid', maxHeight: '250px' }}>
        {!!actionLogs?.length ? (
          <>
            {actionLogs?.map((log, index) => {
              const sameEmployee = actionLogs[index]?.initiatorEmployee.code !== actionLogs[index - 1]?.initiatorEmployee.code;
              const { initiatorEmployee } = log;

              return (
                <Grid key={index} container mb={sameEmployee ? 0 : 0.5}>
                  {index === 0 || sameEmployee ? (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" mb={0.5}>
                        <Grid
                          item
                          sx={{
                            '& .MuiAvatar-root': {
                              fontSize: '14px',
                              width: '24px',
                              height: '24px',
                            },
                          }}
                        >
                          <UserAvatar
                            surname={initiatorEmployee.surname}
                            name={initiatorEmployee.name}
                            src={initiatorEmployee?.avatarUrl}
                          />
                        </Grid>
                        <Grid item ml={1}>
                          <Typography variant="body2">
                            {initiatorEmployee.surname} {initiatorEmployee.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}

                  {<HistoryLogsDropDownBillsLog actionLog={log} />}

                  {actionLogs[index + 1] && actionLogs[index + 1]?.initiatorEmployee.code !== actionLogs[index]?.initiatorEmployee.code ? (
                    <Grid item xs={12} mb={0.5}>
                      <Divider />
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
          </>
        ) : (
          <Box width={'100%'} textAlign="center">
            <Typography variant="body2">Нет данных</Typography>
          </Box>
        )}
      </Grid>
    );
  }, [actionLogs]);

  return (
    <Box onMouseLeave={handleTooltipClose}>
      <Box p={1.5} display="flex" title="История изменений">
        <Badge color="primary" badgeContent={badgeCounter} onMouseEnter={handleTooltipOpen}>
          <FactCheck fontSize="medium" />
        </Badge>
        <Popper placement="top" open={isTooltipOpen} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
          <Paper sx={{ p: 1, maxWidth: '650px' }}>{titleEl}</Paper>
        </Popper>
      </Box>
    </Box>
  );
});
type PositionLogs = {
  titleForPosition?: string;
  textForPositionFrom?: string;
  textForPositionTo?: string;
  showChipPosition?: boolean;
  addInfoForLog?: string;
  createdAt?: string;
  showTitleLog?: boolean;
  pos?: any;
};
// eslint-disable-next-line complexity
export const HistoryLogsDropDownBillsLog: FC<{ actionLog: BillActionLog }> = observer(({ actionLog }) => {
  const log = actionLog as BillActionLog;
  let showChipPosition = false; // отвечает за показ Chip`a с позицией и по совместительству заголовок (titleForPosition)
  let pos = null;
  let titleForPosition = ''; // показывает заголовок рода изменения в позиции (Изменение статуса например) (только для позиции)
  let textForPositionFrom = ''; // текст для было и стало конечно же
  let textForPositionTo = '';
  let addInfoForLog = ''; // дополнительная информация о логе (более подробная информация нежели logType.title) (используется для всего счета не для позиции)
  let showTitleLog = true;

  if (!actionLog) {
    return null;
  }
  const { logData, logType } = log;

  const positionLogs: PositionLogs[] = [];

  if (!logData) {
    // такое может быть, тогда покажется просто logType.title
  } else if ('positionSplitAdd' in logData) {
    if (logData['positionSplitAdd']?.positions?.length) {
      logData['positionSplitAdd']?.positions.forEach(i => {
        positionLogs.push({
          pos: i,
          createdAt: log.createdAt,
          titleForPosition: logType.title,
          addInfoForLog: `${i.productTitle} ${i.quantityUnitTo} ${i.unitShort}`,
          showChipPosition: true,
        });
      });
    } else {
      positionLogs.push({ addInfoForLog: logType.title });
    }
    showTitleLog = false;
  } else if ('positionServiceEdit' in logData) {
    if (logData['positionServiceEdit']?.positions?.length) {
      logData['positionServiceEdit']?.positions.forEach(i => {
        positionLogs.push({
          pos: i,
          createdAt: log.createdAt,
          titleForPosition: logType.title,
          addInfoForLog: i.serviceTitle,
          textForPositionFrom: `${formatPriceCur(i.manualTotalCostFrom)}`,
          textForPositionTo: `${formatPriceCur(i.manualTotalCostTo)}`,
          showChipPosition: true,
        });
      });
    } else {
      positionLogs.push({ addInfoForLog: logType.title });
    }
    showTitleLog = false;
  } else if ('positionUnitCostEdit' in logData) {
    if (logData['positionUnitCostEdit']?.positions?.length) {
      logData['positionUnitCostEdit']?.positions.forEach(i => {
        positionLogs.push({
          pos: i,
          createdAt: log.createdAt,
          titleForPosition: logType.title,
          textForPositionFrom: `${formatPriceCur(i.unitCostFrom)}`,
          textForPositionTo: `${formatPriceCur(i.unitCostTo)}`,
          showChipPosition: true,
        });
      });
    } else {
      positionLogs.push({ addInfoForLog: logType.title });
    }
    showTitleLog = false;
  } else if ('positionProductEdit' in logData) {
    if (logData['positionProductEdit']?.positions?.length) {
      logData['positionProductEdit']?.positions.forEach(i => {
        positionLogs.push({
          pos: i,
          createdAt: log.createdAt,
          titleForPosition: logType.title,
          textForPositionFrom: i.productTitleFrom,
          textForPositionTo: i.productTitleTo,
          showChipPosition: true,
        });
      });
    } else {
      positionLogs.push({ addInfoForLog: logType.title });
    }
    showTitleLog = false;
  } else if ('positionQuantityEdit' in logData) {
    if (logData['positionQuantityEdit']?.positions?.length) {
      logData['positionQuantityEdit']?.positions.forEach(i => {
        positionLogs.push({
          pos: i,
          createdAt: log.createdAt,
          titleForPosition: logType.title,
          textForPositionFrom: i.quantityUnitFrom + (i.unitShort || ''),
          textForPositionTo: i.quantityUnitTo + (i.unitShort || ''),
          showChipPosition: true,
        });
      });
    } else {
      positionLogs.push({ addInfoForLog: logType.title });
    }
    showTitleLog = false;
  } else if ('reservationRequest' in logData) {
    pos = logData['reservationRequest']?.positions?.[0] ?? null;
    if (pos) {
      titleForPosition = logType.title;
      showChipPosition = true;
    } else {
      addInfoForLog = logType.title;
    }
    showTitleLog = false;
  } else if ('reconciliation' in logData) {
    if (logData['reconciliation'].fullReconciled) {
      addInfoForLog = 'Фактурирование всего счета';
    } else {
      titleForPosition = 'Фактурирование позиции';
      pos = logData['reconciliation']?.positions?.[0] ?? null;
      if (pos) {
        showChipPosition = true;
        textForPositionFrom = pos.quantityUnitFrom + pos.unitShort;
        textForPositionTo = pos.quantityUnitTo + pos.unitShort;
      }
    }
    showTitleLog = false;
  } else if ('reservationWithdraw' in logData) {
    if (!logData['reservationWithdraw'].full) {
      pos = logData['reservationWithdraw']?.positions?.[0] ?? null;
      if (pos) {
        showChipPosition = true;
        titleForPosition = 'Снятие резерва по позиции:';
      }
    } else {
      addInfoForLog = 'Резерв снят по всему счёту';
    }
    showTitleLog = false;
  } else if ('prolongation' in logData) {
    textForPositionFrom = `${formatDateSwaggerZ(logData['prolongation'].validUntilFrom)}`;
    textForPositionTo = `${formatDateSwaggerZ(logData['prolongation'].validUntilTo)}`;
  } else if ('autoReserveSettingUpdate' in logData) {
    if (logData['autoReserveSettingUpdate'].positions && logData['autoReserveSettingUpdate'].positions.length) {
      pos = logData['autoReserveSettingUpdate']?.positions?.[0] ?? null;
      if (pos) {
        showChipPosition = true;
        textForPositionFrom = pos.from ? 'Включено' : 'Выключено';
        textForPositionTo = pos.to ? 'Включено' : 'Выключено';
      }
    } else {
      textForPositionFrom = logData['autoReserveSettingUpdate'].from === 'disabled' ? 'Выключено' : 'Включено';
      textForPositionTo = logData['autoReserveSettingUpdate'].to === 'disabled' ? 'Выключено' : 'Включено';
    }
  } else if ('documentDateEdit' in logData) {
    textForPositionFrom = `${formatDateSwaggerZ(logData['documentDateEdit'].documentDateWas)}`;
    textForPositionTo = `${formatDateSwaggerZ(logData['documentDateEdit'].documentDateBecome)}`;
  } else if ('stateUpdate' in logData) {
    textForPositionFrom = logData['stateUpdate'].fromState.title;
    textForPositionTo = logData['stateUpdate'].toState.title;
  } else if ('companySubdivisionEdit' in logData) {
    textForPositionFrom = logData['companySubdivisionEdit'].subdivisionNameFrom;
    textForPositionTo = logData['companySubdivisionEdit'].subdivisionNameTo;
  } else {
    console.warn('Необработанный случай для логов счета', logData, log);
  }

  if (positionLogs?.length) {
    return positionLogs.map((i, index) => (
      <BillEditLogItem
        key={index}
        pos={i.pos}
        addInfoForLog={i.addInfoForLog}
        textForPositionFrom={i.textForPositionFrom}
        textForPositionTo={i.textForPositionTo}
        titleForPosition={i.titleForPosition}
        createdAt={i.createdAt}
        showChipPosition={i.showChipPosition}
      />
    ));
  }

  return (
    <Grid container item xs={12} spacing={0.5}>
      <Grid item>
        <Typography variant="body2" component={'span'}>
          {formatYmdHiLZ(log.createdAt)}
        </Typography>
      </Grid>

      <Grid item ml={1}>
        {showTitleLog && (
          <Typography variant="body2" component={'span'} sx={{ color: actionCodeByColorBills[logType?.code] ?? undefined }}>
            {logType.title || 'Неизвестно'}
            {Object.keys(logData || {})?.length ? ':' : ''}
          </Typography>
        )}
      </Grid>

      <Grid item>
        <Grid container>
          {showChipPosition && (
            <Grid item>
              {(pos?.position && <ChipTitleStyled label={`#${pos?.position}`} sx={{ backgroundColor: '#AFDEEF', color: '#465960' }} />) ||
                null}
              <Typography variant="body2" component={'span'}>
                {titleForPosition}
              </Typography>
            </Grid>
          )}
          {addInfoForLog && (
            <Grid item>
              <Typography variant="body2" component={'span'}>
                {addInfoForLog}
              </Typography>
            </Grid>
          )}

          {textForPositionFrom && textForPositionTo && (
            <Grid item>
              <Typography variant="body2" component={'span'}>
                {textForPositionFrom}
              </Typography>
              <Typography variant="body2" component={'span'} fontWeight={'bold'} color={'#5CBADE'}>
                {' > '}
              </Typography>
              <Typography variant="body2" component={'span'}>
                {textForPositionTo}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

interface BillEditLogItemProps {
  createdAt?: string;
  showChipPosition?: boolean;
  pos?: any;
  titleForPosition?: string;
  textForPositionFrom?: string;
  textForPositionTo?: string;
  addInfoForLog?: string;
}

const BillEditLogItem: FC<BillEditLogItemProps> = observer(
  ({ createdAt, showChipPosition, pos, titleForPosition, textForPositionFrom, textForPositionTo, addInfoForLog }) => {
    return (
      <Grid container item xs={12} spacing={0.5}>
        <Grid item>
          <Typography variant="body2" component={'span'}>
            {formatYmdHiLZ(createdAt)}
          </Typography>
        </Grid>
        {showChipPosition && (
          <Grid item>
            {(pos?.position && <ChipTitleStyled label={`#${pos?.position}`} sx={{ backgroundColor: '#AFDEEF', color: '#465960' }} />) ||
              null}
          </Grid>
        )}
        <Grid item>
          <Typography variant="body2" component={'span'}>
            {titleForPosition}
          </Typography>
        </Grid>
        {addInfoForLog && (
          <Grid item>
            <Typography variant="body2" component={'span'}>
              {addInfoForLog}
            </Typography>
          </Grid>
        )}
        {textForPositionFrom && textForPositionTo && (
          <Grid item>
            <Typography variant="body2" component={'span'}>
              {textForPositionFrom}
            </Typography>
            <Typography variant="body2" component={'span'} fontWeight={'bold'} color={'#5CBADE'}>
              {' > '}
            </Typography>
            <Typography variant="body2" component={'span'}>
              {textForPositionTo}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
);
