import { Autocomplete, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { CompanyPaymentDetails } from 'src/api/marketx';
import { DealItemStore } from 'src/store/DealItemStore';

type DealSellerBankSelectProps = {
  dealStore: DealItemStore;
};

export const DealSellerBankSelect: FC<DealSellerBankSelectProps> = observer(({ dealStore }) => {
  const deal = dealStore.deal;

  const [sellerBank, setSellerBank] = useState<CompanyPaymentDetails>(null);

  const handleUpdateSellerBank = (sellerBank: CompanyPaymentDetails): void => {
    setSellerBank(sellerBank);
    dealStore
      .updateDeal({ sellerPaymentDetailCode: sellerBank.code })
      .catch(e => console.warn('Не удалось изменить банковский счет компании', e));
  };

  useEffect(() => {
    if (deal.sellerPaymentDetailCode) {
      if (!deal.sellerPaymentDetails?.some(sellerBank => sellerBank.code === deal.sellerPaymentDetailCode)) {
        deal.sellerPaymentDetails = [];
        deal.sellerPaymentDetails.push({
          code: deal.sellerPaymentDetailCode,
          accountNumber: deal.sellerBankAccountNumber,
          bankTitle: deal.sellerBankTitle,
        });
      }

      const initialSellerBank = deal.sellerPaymentDetails.filter(sellerBank => deal.sellerPaymentDetailCode === sellerBank.code);

      setSellerBank(initialSellerBank ? initialSellerBank[0] : null);
    }
  }, [deal.sellerPaymentDetailCode, deal.sellerPaymentDetails]);

  return (
    <Autocomplete
      value={sellerBank}
      onChange={(_, sellerBank) => handleUpdateSellerBank(sellerBank)}
      size="small"
      disablePortal
      getOptionLabel={i => `${i.accountNumber} ${i.bankTitle}`}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      disableClearable
      options={deal.sellerPaymentDetails || []}
      renderInput={({ inputProps, ...rest }) => (
        <TextField
          {...rest}
          inputProps={{ ...inputProps, readOnly: true }}
          sx={{
            '.MuiFormLabel-root': {
              fontSize: '16px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              fontSize: '16px',
            },
            '.MuiInputBase-root': {
              padding: '4px 14px',
              fontSize: '14px',
            },
            '.MuiFormLabel-root[data-shrink="false"]': {
              fontSize: '14px',
            },
          }}
          label="Банковский счет компании"
        />
      )}
      disabled={dealStore.isViewOnly() || dealStore.isLoading}
    ></Autocomplete>
  );
});
