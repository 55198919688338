import { VersionStore } from './Global/VersionStore';
import { FeedbackFormStore } from './FeedbackForm';
import { AuthStore } from './AuthStore';
import { ContractListStore } from './ContractListStore';
import { ErrorStore } from './ErrorStore';
import { CustomerMarginalityChartsDataStore } from './CustomerMarginalityChartsDataStore';
import { DealAddressStore } from './DealAddressStore';
import { DealConsigneeListStore } from './DealConsigneeListStore';
import { DealItemStore } from './DealItemStore';
import { LaunchStore } from './LaunchStore';
import { TopBarStore } from './TopBarStore';
import { SnackbarStore } from './SnackbarStore';
import { WebSocketStore } from './Global/WebSocketStore';
import { RouterStore } from './RouterStore';
import { OfficeListStore } from './Company/OfficeListStore';
import { ApiStore } from './Global/ApiStore';
import { NotificationsStore } from './NotificationsStore';
import { EmployeesMarginalityStore } from './EmployeesMarginalityStore';
import { RatingOfManagersStore } from './RatingOfManagersStore';
import { ChartsDataStore } from '../views/deals/DealPosition/ChartsDataStore';
import { AgreementItemStore } from './Clients/AgreementItemStore';
import { DevPanelStore } from './Global/DevPanelStore';
import { F1ClientStore } from './Global/F1ClientStore';
import { EventManager } from './Global/EventManager';
import { ProductExtensionsStore } from './Catalog/ProductExtensionsStore';
import { webConfig } from '../config';
import { WebSocketCollieStore } from './Global/WebSocketCollieStore';
import { CallStore } from './CallStore';
import { ClientItemStore } from './ClientItemStore';
import { FreezeItemStore } from './FreezeItemStore';
import { ProductItemStore } from './ProductItemStore';

type Class<T> = new (...args: any[]) => T;

type StoreItem = {
  createdAt: Date;
  className: string;
  instance: any;
};

type RootStoreCoreData = {
  webConfig: WebConfigProvider;
  rootStoresCount: number; // количество созданных инстансов рут-стора
  localStoresCount: number; // количество созданных локальных сторов приложения
};

interface WebConfigProvider {
  apiWsUrl?: string;
  apiWsEnabled?: boolean;
}

// https://mobx.js.org/defining-data-stores.html#combining-multiple-stores
export class RootStore {
  /**
   * Якорь, привязанный к компоненту.
   * К этому якорю крепятся локальные сторы. Так они будут удалены после удаления самого компонента.
   * Заполняется при вызове хука useRootStore в локальных рут-сторах
   */
  anchor: unknown;

  coreData: RootStoreCoreData;

  /**  Глобальные сторы, существуют с момента первого обращения и не очищаются. */
  globalStores: Record<string, StoreItem>;
  /**
   * Локальные сторы, привязаны к компонентам,
   * удаляются после удаления якорей, к которым привязаны.
   */
  localStores: WeakMap<{}, Record<string, StoreItem>> = new WeakMap<{}, Record<string, StoreItem>>();

  /**
   * Собственный номер стора
   */
  private readonly selfStoreNumberVal: number;

  constructor(anchor: {}, coreRootStore: RootStore) {
    this.anchor = anchor;
    if (coreRootStore) {
      // текущий инстанс -- локальный рут стор какого-то компонента
      this.globalStores = coreRootStore.globalStores;
      this.localStores = coreRootStore.localStores;
      this.coreData = coreRootStore.coreData;
      this.selfStoreNumberVal = ++this.coreData.rootStoresCount;
    } else {
      // текущий инстанс -- корневой рут стор приложения
      this.globalStores = {};
      this.localStores = new WeakMap<{}, Record<string, StoreItem>>();
      this.coreData = <RootStoreCoreData>{
        webConfig: null,
        rootStoresCount: 1,
        localStoresCount: 0,
      };
      this.selfStoreNumberVal = 1;
      if (typeof window === 'object') {
        // для отладки через консоль
        // @ts-ignore
        // window.rootStore = this;
      }
    }
  }

  getGlobalStore<T>(name: string, storeClass: Class<T>): T {
    let store = this.globalStores[name];
    // For server side rendering always create a new store
    if (!store) {
      store = <StoreItem>{
        createdAt: new Date(),
        className: storeClass.name,
        instance: new storeClass(this),
      };
      if (typeof window === 'undefined') {
        return store.instance;
      }
      this.globalStores[name] = store;
    }
    return store.instance as T;
  }

  getLocalStore<T>(name: string, storeClass: Class<T>, init?: (store: T) => void): T {
    return this.getLocalStoreFor(this.anchor, name, storeClass, init);
  }

  getLocalStoreFor<T>(anchor: {}, name: string, storeClass: Class<T>, init?: (store: T) => void): T {
    if (!anchor) {
      console.error('data anchor required for', name);
      return null;
    }
    let items = this.localStores.get(anchor);
    if (!items) {
      items = {};
      this.localStores.set(anchor, items);
    }
    let item = items[name];
    if (!item) {
      item = <StoreItem>{
        createdAt: new Date(),
        className: storeClass.name,
        instance: new storeClass(this),
      };
      items[name] = item;
      this.coreData.localStoresCount++;
      if (init) {
        init(item.instance);
      }
    }
    return item.instance as T;
  }
  getApiStore(): ApiStore {
    return this.getGlobalStore('ApiStore', ApiStore);
  }
  getAuth(): AuthStore {
    return this.getGlobalStore('Auth', AuthStore);
  }
  getDevPanel(): DevPanelStore {
    return this.getGlobalStore('DevPanel', DevPanelStore);
  }
  getEmployeesMarginality(init: (store: EmployeesMarginalityStore) => void): EmployeesMarginalityStore {
    return this.getLocalStore('EmployeesMarginalityStore', EmployeesMarginalityStore, init);
  }
  getEventManager(): EventManager {
    return this.getGlobalStore('EventManager', EventManager);
  }
  getRatingOfManagersStore(init: (store: RatingOfManagersStore) => void): RatingOfManagersStore {
    return this.getLocalStore('RatingOfManagersStore', RatingOfManagersStore, init);
  }
  getProductExtensionsStore(): ProductExtensionsStore {
    return this.getGlobalStore('ProductExtensionsStore', ProductExtensionsStore);
  }
  getChartsDataStore(): ChartsDataStore {
    return this.getGlobalStore('ChartsDataStore', ChartsDataStore);
  }
  getCustomerMarginalityChartsDataStore(): CustomerMarginalityChartsDataStore {
    return this.getLocalStore('CustomerMarginalityChartsDataStore', CustomerMarginalityChartsDataStore);
  }
  getContractList(): ContractListStore {
    return this.getLocalStore('ContractListStore', ContractListStore);
  }
  getDealItem(init: (store: DealItemStore) => void): DealItemStore {
    return this.getLocalStore('DealItem', DealItemStore, init);
  }
  getProductItemStore(init: (store: ProductItemStore) => void): ProductItemStore {
    return this.getLocalStore('ProductItemStore', ProductItemStore, init);
  }
  getClientItemStore(init: (store: ClientItemStore) => void): ClientItemStore {
    return this.getLocalStore('ClientItemStore', ClientItemStore, init);
  }
  getAgreementItemStore(init: (store: AgreementItemStore) => void): AgreementItemStore {
    return this.getLocalStore('AgreementItemStore', AgreementItemStore, init);
  }
  getFreezeItemStore(init: (store: FreezeItemStore) => void): FreezeItemStore {
    return this.getLocalStore('FreezeItemStore', FreezeItemStore, init);
  }
  getDealAddress(): DealAddressStore {
    return this.getGlobalStore('DealAddress', DealAddressStore);
  }
  getDealConsigneeList(): DealConsigneeListStore {
    return this.getGlobalStore('DealConsigneeListStore', DealConsigneeListStore);
  }
  getError(): ErrorStore {
    return this.getGlobalStore('ErrorStore', ErrorStore);
  }
  getFeedbackForm(): FeedbackFormStore {
    return this.getGlobalStore('FeedbackForm', FeedbackFormStore);
  }
  getLaunch(init: (store: LaunchStore) => void): LaunchStore {
    return this.getLocalStore('Launch', LaunchStore, init);
  }
  getOfficeListStore(): OfficeListStore {
    return this.getLocalStore('OfficeListStore', OfficeListStore, null);
  }
  getRouter(): RouterStore {
    return this.getGlobalStore('RouterStore', RouterStore);
  }
  getSnackbar(): SnackbarStore {
    return this.getGlobalStore('SnackbarStore', SnackbarStore);
  }
  getNotificationsStore(): NotificationsStore {
    return this.getGlobalStore('NotificationsStore', NotificationsStore);
  }
  getTopBarStore(): TopBarStore {
    return this.getGlobalStore('TopBarStore', TopBarStore);
  }
  getVersion(): VersionStore {
    return this.getGlobalStore('VersionStore', VersionStore);
  }
  getWebSocket(): WebSocketStore {
    return this.getGlobalStore('WebSocketStore', WebSocketStore);
  }
  getWebCollieSocket(): WebSocketCollieStore {
    return this.getGlobalStore('WebSocketCollieStore', WebSocketCollieStore);
  }
  getCallStore(): CallStore {
    return this.getGlobalStore('CallStore', CallStore);
  }
  getF1ClientStore(): F1ClientStore {
    return this.getGlobalStore('F1ClientStore', F1ClientStore);
  }
  selfStoreNumber(): number {
    return this.selfStoreNumberVal;
  }
  nextStoreNumber(): number {
    return ++this.coreData.localStoresCount;
  }

  getWebConfig(): WebConfigProvider {
    if (this.coreData.webConfig) {
      return this.coreData.webConfig;
    }

    return webConfig;
  }

  setWebConfig(cfg: WebConfigProvider): void {
    this.coreData.webConfig = cfg;
  }
}

export function createMockRootStore(): RootStore {
  const rs = new RootStore({}, null);
  rs.setWebConfig({ apiWsEnabled: false });

  return rs;
}
