import { Box, Divider, Grid, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC, useEffect, useRef, useState } from 'react';
import { ContractCostPosition } from 'src/api/marketx';
import { TableRowTitle } from 'src/components/Catalog/Table/CatalogTableRowVirtualizeItemView';
import { AppDealPosition } from 'src/slices/AppDeal';

type DealPositionAdditionalTabHeaderProps = {
  isDialogOpen: boolean;
  Icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      })
    | string;
  position: AppDealPosition | ContractCostPosition;
  dividerMb?: number;
};

export const DealPositionAdditionalTabHeader: FC<DealPositionAdditionalTabHeaderProps> = ({ position, Icon, isDialogOpen, dividerMb }) => {
  const [isInDialog, setIsInDialog] = useState(false);

  const parentRef = useRef(null);

  const mb = dividerMb ? dividerMb : 1;

  useEffect(() => {
    if (parentRef.current) {
      const hasClass = parentRef.current?.offsetParent?.classList.contains('MuiPaper-root');
      setIsInDialog(hasClass);
    }
  }, []);

  return (
    <Box ref={parentRef} sx={{ width: `${isInDialog ? '100%' : '0px'}` }}>
      {isDialogOpen && isInDialog && (
        <>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              {typeof Icon === 'string' ? (
                <Typography sx={{ color: '#757575', ml: '5px' }}>₽/т</Typography>
              ) : (
                <Icon sx={{ color: '#757575' }} />
              )}
            </Grid>
            <Grid item>
              <Typography fontSize={'12px'} color="text.secondary">
                <TableRowTitle item={position.product} target={'_blank'} />
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', mb: mb }} />
        </>
      )}
    </Box>
  );
};
