import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContentText from '@mui/material/DialogContentText';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import { DealWarehouseSelect } from './DealWarehouseSelect';
import { DealApprovalStateEnum } from '../../../api/marketx';
import { deactivateDeal } from '../../clients/lib';
import { Typography } from '@mui/material';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { MxLabel } from '@mx-ui/ui';
import { DealDistributorSelect } from './DealDistributorSelect';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomPopper } from '../components/CustomPopper';
import Box from '@mui/material/Box';
import { ThreadLink } from '../../../components/Bills/ThreadLink';
import { ContractSelect, formatContractTitle } from '../../../components/Deals/ContractSelect/ContractSelect';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link as MxLink } from '@mx-ui/ui';
import { MaxDocumentDate } from '../../../types/constants';
import { DealSellerBankSelect } from './DealSellerBankSelect';

export const SummaryInfoSkeleton = (): JSX.Element => {
  return (
    <Grid item md={6} lg={4} xs={12}>
      <Paper sx={{ height: '100%', padding: 1, position: 'relative' }}>
        <Grid container height={'100%'} flexDirection={'column'} flexWrap={'nowrap'} justifyContent={'space-between'}>
          <Grid item container>
            <Grid xs={12} item>
              <Skeleton variant="text" width={'50%'} />
            </Grid>
            <Grid xs={12} item mb={2}>
              <Skeleton variant="text" width={'50%'} />
            </Grid>
            {[1, 2, 3, 4].map(i => (
              <Grid xs={12} item key={i} mb={2}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={'80%'} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={'50%'} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <Skeleton variant="rectangular" width={'100%'} height={70} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

interface DealSummaryInfoProps {
  dealStore: DealItemStore;
}

export const DealSummaryInfo: FC<DealSummaryInfoProps> = observer(({ dealStore }): JSX.Element => {
  const rootStore = useRootStore();
  const handleProlongClick = useCallback(() => {
    dealStore.prolong().catch(r => console.warn('unable to prolong', r));
  }, [dealStore]);

  const apiStore = useRootStore().getApiStore();
  const [deactivating, setDeactivating] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const [warningAnchor, setWarningAnchor] = useState(null);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const handleWarningOpen = (event): void => {
    setWarningAnchor(event.target);
    setIsWarningOpen(true);
  };
  const handleWarningClose = (): void => {
    setWarningAnchor(null);
    setIsWarningOpen(false);
  };
  const handleDeactivateOpen = (): void => {
    setDeactivateOpen(true);
  };
  const handleDeactivateCancel = (): void => {
    setDeactivateOpen(false);
  };
  const handleDeactivateConfirm = useCallback(() => {
    setDeactivating(true);
    const deactivateAction = deactivateDeal(apiStore, dealStore.deal.code);

    deactivateAction.catch(e => {
      console.error(e);
      setDeactivating(false);
      setDeactivateOpen(false);
    });

    deactivateAction.then(() => {
      dealStore.reload();
      setDeactivating(false);
      setDeactivateOpen(false);
    });
  }, [dealStore]);

  const stateCode = dealStore.deal?.state || 'not_active';
  const approvalState = dealStore.deal?.approval?.state;
  const enableActivate =
    (stateCode === 'expired' ||
      stateCode === 'not_active' ||
      stateCode === 'canceled' ||
      (stateCode === 'bill_invoiced' && dealStore.deal.editingDisableReasons?.some(i => i.code === 'expired' || i.code === 'canceled'))) &&
    approvalState !== DealApprovalStateEnum.Approved &&
    approvalState !== DealApprovalStateEnum.SelfApproved;

  let enableDeactivate = false;

  const activeDate =
    new Date(dealStore.deal.validUntil) >= new Date() && new Date(dealStore.deal.validUntil).getFullYear() !== MaxDocumentDate
      ? `Действительна до ${formatDateSwaggerZ(dealStore.deal.validUntil)}`
      : '';

  if (stateCode === 'active') {
    enableDeactivate = true;
  }

  const contractsStore = rootStore.getContractList();
  useEffect(() => {
    contractsStore.loadListForDeal(dealStore.deal);
  }, [contractsStore, dealStore, dealStore.deal?.customerCode]);

  const handleContractChange = useCallback(
    (contractCode: string | null): void => {
      dealStore.updateDeal({ contractCode: contractCode }).catch(r => console.warn('handleContractChange', r));
    },
    [dealStore]
  );
  const contractMustValue = dealStore?.deal?.contract?.code
    ? {
        text: formatContractTitle(dealStore.deal?.contract),
        value: dealStore.deal?.contract?.code,
        customerCode: dealStore.deal.partners?.payer?.code,
      }
    : undefined;

  if (!dealStore.isLoaded) {
    return <SummaryInfoSkeleton />;
  }

  return (
    <>
      <Grid item md={6} lg={4} xs={12}>
        <Paper sx={{ height: '100%', padding: '8px' }}>
          <Grid container flexDirection={'column'} flexWrap={'nowrap'} justifyContent={'space-between'} height={'100%'}>
            <Grid item container alignItems={'center'}>
              {new Date(dealStore.deal.validUntil) >= new Date() && (
                <Grid item container xs={12} mb={1}>
                  <Typography variant="body2" color={'#427B33'}>
                    {activeDate}
                  </Typography>
                </Grid>
              )}
              {!dealStore.isDeleted
                ? (enableActivate || (enableDeactivate && !dealStore.isViewOnly())) && (
                    <Grid item container xs={12} justifyContent={'space-between'} mb={2}>
                      {enableActivate && (
                        <Grid item>
                          <Button size="small" variant="contained" color="secondary" onClick={handleProlongClick}>
                            <Typography variant="body2" color={'#fff'} sx={{ cursor: 'pointer' }} fontSize={'13px'}>
                              Активировать
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                      {enableDeactivate && !dealStore.isViewOnly() && (
                        <Grid item>
                          <Button size="small" variant="contained" color="warning" onClick={handleDeactivateOpen}>
                            <Typography variant="body2" color={'#fff'} sx={{ cursor: 'pointer' }} fontSize={'13px'}>
                              Деактивировать
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )
                : null}
              <Grid item container xs={12} alignItems={'center'} mb={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    Номер документа
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    {dealStore.dealCode || ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems={'center'} mb={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    Создана
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    {formatDateSwaggerZ(dealStore.deal.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems={'center'} mb={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    Статус
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <MxLabel
                    style={{ display: 'inline', paddingTop: '4px' }}
                    color={dealStore.deal.state === 'not_active' ? 'error' : dealStore.deal.state === 'approval' ? 'warning' : 'success'}
                  >
                    {dealStore.deal?.stateTitle || 'Неизвестно'}
                  </MxLabel>
                </Grid>
              </Grid>
              <Grid item container xs={12} mb={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color={'text.secondary'}>
                    Счет
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {Boolean(dealStore.deal?.billDocumentNumber) ? (
                    <Grid item xs={12}>
                      <Box position={'relative'}>
                        <Typography color="text.secondary" variant="body2">
                          <MxLink
                            href={`/app/bills/${dealStore.deal?.billDocumentNumber}`}
                            color="inherit"
                            title="Перейти к счету"
                            typographySx={{ fontSize: 14 }}
                          >
                            {dealStore.deal?.billDocumentNumber}
                          </MxLink>
                          <span>{` от`}</span> {formatDateSwaggerZ(dealStore.deal?.billDocumentDate)}
                        </Typography>
                        <Box position={'absolute'} left={'-30px'} sx={{ top: '50%', transform: 'translateY(-50%)' }}>
                          <ThreadLink link={`/app/thread/bills/${encodeURIComponent(dealStore.deal?.billDocumentNumber)}`} sx={{ py: 0 }} />
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    '—'
                  )}
                  {dealStore.deal?.billValidUntil &&
                    new Date(dealStore.deal?.billValidUntil).getFullYear() !== MaxDocumentDate &&
                    new Date() <= new Date(dealStore.deal?.billValidUntil) && (
                      <Grid item xs={12}>
                        <Typography color={'#427B33'} variant="body2" fontSize={'12px'} fontWeight={500}>
                          действителен до {formatDateSwaggerZ(dealStore.deal?.billValidUntil)}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container alignItems={'center'}>
              {/* Договор */}
              <Grid item xs={12} mb={2}>
                <Grid container alignItems={'center'}>
                  <Grid item flex={'1 1 auto'}>
                    <ContractSelect
                      isViewOnly={dealStore.isViewOnly()}
                      contracts={contractsStore}
                      current={dealStore.deal?.contract?.code}
                      mustValue={contractMustValue}
                      onChange={handleContractChange}
                      clientCode={dealStore.deal.customerCode}
                      currentPayerCode={dealStore.deal.partners?.payer?.code}
                    />
                  </Grid>
                  <Grid item>
                    <Box ml={'4px'} mt={'-1px'}>
                      <MxLink
                        href={`/app/contracts/${encodeURIComponent(dealStore.deal?.contract?.code)}?customerCode=${
                          dealStore.deal.customerCode
                        }&branchOfficeCode=${dealStore.deal.branchOfficeCode}`}
                      >
                        <IconButton
                          size="small"
                          title="Перейти к договору"
                          disabled={!dealStore.deal?.contract?.code || dealStore.isSaving || dealStore.hasUpdates()}
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </MxLink>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={2}>
                {!!dealStore.deal && <DealSellerBankSelect dealStore={dealStore} />}
              </Grid>
              <Grid item xs={12} mb={2}>
                {!!dealStore.deal && <DealWarehouseSelect dealStore={dealStore} />}
              </Grid>
              <Grid item xs={12}>
                <Grid container flexDirection={'column'} flexWrap={'nowrap'}>
                  <Grid item>{!!dealStore.deal && <DealDistributorSelect dealStore={dealStore} />}</Grid>
                  {Boolean(
                    dealStore.deal?.motivationEmployee &&
                      dealStore.deal?.motivationEmployee.code !== dealStore.deal?.distributor?.employeeCode
                  ) && (
                    <Grid item mt={1}>
                      <Grid container alignItems={'center'}>
                        <Grid item onMouseEnter={handleWarningOpen} onMouseLeave={handleWarningClose}>
                          <WarningIcon color="warning" fontSize="medium" sx={{ marginRight: '5px' }} />
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontSize: '12px' }}>
                            <Typography sx={{ fontSize: '12px', color: 'gray' }} component="span">
                              Реализатор по замещению:{' '}
                            </Typography>
                            {dealStore.deal.motivationEmployee?.surname} {dealStore.deal.motivationEmployee?.name}{' '}
                            {dealStore.deal.motivationEmployee?.patronymic}
                          </Typography>
                        </Grid>
                      </Grid>
                      <CustomPopper
                        isOpen={isWarningOpen}
                        anchorEl={warningAnchor}
                        onClose={() => handleWarningClose()}
                        style={{ width: '300px' }}
                      >
                        <Typography component="span">Мотивация будет зачислена реализатору по замещению</Typography>
                      </CustomPopper>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog
        open={deactivateOpen}
        onClose={handleDeactivateCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Деактивировать заявку?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Заявка {dealStore.deal.code} будет деактивирована.</span>
          </DialogContentText>
          {deactivating && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeactivateCancel}
            disabled={deactivating}
            sx={{ color: theme => (theme.palette.mode === 'dark' ? 'secondary.light' : 'primary') }}
          >
            Отмена
          </Button>
          <Button
            onClick={handleDeactivateConfirm}
            autoFocus
            disabled={deactivating}
            sx={{ color: theme => (theme.palette.mode === 'dark' ? 'secondary.light' : 'primary') }}
          >
            Деактивировать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
