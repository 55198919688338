import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { ReservesStore } from '../../../store/ReservesStore';
import { ReserveDetailsV2 } from './ReserveDetailsV2';
import { Divider, Grid, Link, Typography } from '@mui/material';
import { Product, ProductReservesRequestReserveStateCodesEnum } from '../../../api/marketx';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { TableRowTitle } from '../../Catalog/Table/CatalogTableRowVirtualizeItemView';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import { TooltipForReserveListHeader } from './TooltipForReserveListHeader';

interface TooltipForReserveListProps {
  icon?: string;
  documentNumber?: string;
  branchOfficeCode?: string;
  reserveStore?: ReservesStore;
  product: Product;
  nomenclatureCodes?: string[];
  warehouseCodes?: string[];
  reserveStateCodes?: ProductReservesRequestReserveStateCodesEnum[];
  warehouseCode?: string;
  mustRender?: any;
  isForSoftReserve?: boolean;
  autoReserveEnabled?: boolean;
  isDialogOpen?: boolean;
  onTooltipForReserveOpen?: (value: boolean) => void;
  isFromProductTab?: boolean;
}

export const styleForCatalogModel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '94vw',
  boxShadow: 24,
  borderRadius: '15px',
};

export const TooltipForReserveList: FC<TooltipForReserveListProps> = observer(
  ({
    icon = 'catalog',
    reserveStore,
    product,
    documentNumber,
    nomenclatureCodes,
    branchOfficeCode,
    isForSoftReserve,
    warehouseCodes,
    warehouseCode,
    autoReserveEnabled,
    mustRender,
    reserveStateCodes,
    isDialogOpen = true,
    isFromProductTab = false,
    onTooltipForReserveOpen,
  }): JSX.Element => {
    if (!reserveStore) {
      const rootStore = useRootStore();
      const initReserveStore = useMemo(() => {
        const store = new ReservesStore(rootStore);
        if (warehouseCodes?.length) {
          store.setWarehouseCodes(warehouseCodes);
        }

        if (nomenclatureCodes) {
          store.loadCatalogNomenclatureReserves(nomenclatureCodes, false);
        } else {
          store.loadCatalogProductReserves(product.code, branchOfficeCode, false, isForSoftReserve, undefined, autoReserveEnabled);
        }
        return store;
      }, [rootStore, product.code, autoReserveEnabled, mustRender]);

      return (
        <Grid sx={{ height: '100%', display: 'grid', gridTemplateColumns: 'minmax(300px, 1fr)' }}>
          <Grid
            container
            spacing={0}
            className="withCustomScroll"
            sx={{ maxHeight: '100%', display: 'grid', gridTemplateColumns: 'minmax(320px, 1fr)', p: 1 }}
            id={`${product.code}ScrollableReserveContainer`}
          >
            {isDialogOpen && !isFromProductTab && (
              <TooltipForReserveListHeader
                isForSoftReserve={isForSoftReserve}
                product={product}
                warehouseCode={warehouseCode}
                warehouseCodes={initReserveStore.warehouseCodes}
                reserveStateCodes={reserveStateCodes}
                autoReserveEnabled={autoReserveEnabled}
              />
            )}

            <Grid item xs={12}>
              <ReserveDetailsV2
                reserveStore={initReserveStore}
                withSkeleton
                productCode={product.code}
                documentNumber={documentNumber}
                isForSoftReserve={isForSoftReserve}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    const [open, setOpen] = useState(false);
    const handleTooltipClose = (): void => {
      setOpen(false);
      onTooltipForReserveOpen && onTooltipForReserveOpen(false);
      reserveStore.resetReserves();
    };
    const handleTooltipOpen = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.stopPropagation();
        setOpen(true);
        onTooltipForReserveOpen && onTooltipForReserveOpen(true);
        reserveStore.loadCatalogProductReserves(
          product.code,
          branchOfficeCode,
          false,
          isForSoftReserve,
          warehouseCode,
          undefined,
          reserveStateCodes
        );
      },
      [reserveStore, product, warehouseCode, isForSoftReserve, reserveStateCodes]
    );

    if (!open) {
      return (
        <IconButton size="small" onClick={e => handleTooltipOpen(e)}>
          {icon === 'catalog' && <InfoIcon fontSize="small" color={'primary'} />}
          {icon === 'deal' && <LockPersonRoundedIcon fontSize="small" />}
        </IconButton>
      );
    }

    return (
      <Box>
        <IconButton size="small" onClick={handleTooltipOpen}>
          {icon === 'catalog' && <InfoIcon fontSize="small" color={'primary'} />}
          {icon === 'deal' && <LockPersonRoundedIcon fontSize="small" />}
        </IconButton>

        <DialogContainer
          open={open}
          onClose={handleTooltipClose}
          maxWidth={'lg'}
          infiniteScrollId={`${product.code}ScrollableReserveContainer`}
          dialogContent={
            <Box>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: '100%',
                    backgroundColor: 'background.paper',
                    borderRadius: '15px 15px 0 0',
                    p: '8px 8px 8px 0',
                    zIndex: 1100,
                  }}
                >
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      {isForSoftReserve ? (
                        <DynamicFeedOutlinedIcon sx={{ color: '#757575' }} />
                      ) : (
                        <LockPersonRoundedIcon sx={{ color: '#757575' }} />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'12px'} sx={{ pl: '2px' }} color="text.secondary">
                        <TableRowTitle item={product} />
                      </Typography>
                    </Grid>
                    <Grid item>
                      {isForSoftReserve ? (
                        <Link
                          href={`/app/reserves-awaiting?productCode=${product.code}${
                            warehouseCode
                              ? '&warehouseCodes=' + warehouseCode
                              : reserveStore.warehouseCodes?.length
                              ? '&warehouseCodes=' + reserveStore.warehouseCodes[0]
                              : ''
                          }${autoReserveEnabled ? '&autoReserveEnabled=true' : ''}${
                            reserveStateCodes?.length ? `&reserveStateCodes=${reserveStateCodes.join(',')}` : ''
                          }`}
                          sx={{ textTransform: 'uppercase', fontSize: '14px' }}
                          underline="none"
                          target="_blank"
                        >
                          {'К ожиданиям резерва >>'}
                        </Link>
                      ) : (
                        <Link
                          href={`/app/reserves?productCode=${product.code}${
                            warehouseCode
                              ? '&warehouseCodes=' + warehouseCode
                              : reserveStore.warehouseCodes?.length
                              ? '&warehouseCodes=' + reserveStore.warehouseCodes[0]
                              : ''
                          }${reserveStateCodes?.length ? `&reserveStateCodes=${reserveStateCodes.join(',')}` : ''}`}
                          sx={{ textTransform: 'uppercase', fontSize: '14px' }}
                          underline="none"
                          target="_blank"
                        >
                          {'К резервам >>'}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ReserveDetailsV2
                    reserveStore={reserveStore}
                    withSkeleton
                    productCode={product.code}
                    isForSoftReserve={isForSoftReserve}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
      </Box>
    );
  }
);
