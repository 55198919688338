import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { ServicesDropDown } from './ServicesDropDown';
import { formatNumber3, formatPrice, formatPriceCur } from '@mx-ui/helpers';
import { AppDeal, AppDealPosition, AppDealPositionService } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import ConstructionIcon from '@mui/icons-material/Construction';
import { DealPositionAdditionalTabHeader } from './DealPositionAdditionalTabHeader';

const TableCustom = styled(Table)(() => ({
  '& .MuiTableCell-sizeSmall': {
    padding: '0px 2px 5px 0px',
  },
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
}));

export const rusCount = (count: number | any): string => {
  let part = count.toString();

  if (part.length > 2) {
    part = part.substr(part.length - 2, 2);
    if (part.substr(0, 1) * 1 > 1) {
      part = part.substr(1);
    }
  }

  const num = part * 1;

  if (num === 0) {
    return `Услуги не выбраны`;
  } else {
    return `Сумма услуг `;
  }
};

interface DealPositionAdditionalTabServicesProps {
  dealStore: DealItemStore;
  position?: AppDealPosition;
  isViewOnly?: boolean;
}

export const DealPositionAdditionalTabServices: FC<DealPositionAdditionalTabServicesProps> = ({
  dealStore,
  isViewOnly,
  position,
}): JSX.Element => {
  const appliedServicesSum = (position?.serviceTotalCost || 0) + (position?.distributeDeliverySum || 0) + (position?.creditTotalCost || 0);
  const availableServices = [...(position?.services || [])];
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Включенные сервисы (те, которыми пользователь может управлять)
  const enabledServices = availableServices.filter(s => (s.enabled || s.active) && s.totalCost > 0);
  // Все примененные сервисы (включая добавленные автоматически)
  const appliedServices = [...enabledServices];

  const isIgnoredServiceCost = (deal: AppDeal, position: AppDealPosition, service: AppDealPositionService): boolean => {
    return !service.active;
  };

  const baseUnit = position?.baseUnit || 'т';

  const onOpenDialog = (isOpen: boolean): void => {
    setIsDialogOpen(isOpen);
  };

  return (
    !position.isService && (
      <TabSelectWrapper onOpen={onOpenDialog}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          className="withCustomScroll"
          sx={{ height: '100%' }}
          justifyContent={'space-between'}
        >
          <DealPositionAdditionalTabHeader Icon={ConstructionIcon} isDialogOpen={isDialogOpen} position={position} />
          <Box display={'flex'} flexDirection={'column'}>
            <Box pt={1} pl={1}>
              <Typography color="text.secondary" variant="subtitle2" fontWeight={600}>
                {rusCount(appliedServices.length)}&nbsp;
                {!!appliedServices.length && (
                  <Chip
                    label={`${formatPrice(appliedServicesSum)} ₽`}
                    sx={{
                      height: '22px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      backgroundColor: '#808185',
                      color: theme => theme.palette.text.light,
                    }}
                  />
                )}
              </Typography>
            </Box>
            <Box p={1}>
              {!!appliedServices.length && (
                <Grid container>
                  <TableCustom size="small">
                    <TableBody>
                      {appliedServices.map(service => (
                        <TableRow key={service.code}>
                          <TableCell component="th" align="left">
                            <Typography variant="caption" fontWeight={500} color="text.secondary">
                              {service.title}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              color="text.successMx"
                              sx={{
                                textDecoration: isIgnoredServiceCost(dealStore.deal, position, service) ? 'line-through' : 'none',
                              }}
                            >
                              {formatPriceCur(service.totalCost)}{' '}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              color="text.greyMx"
                              sx={{
                                textDecoration: isIgnoredServiceCost(dealStore.deal, position, service) ? 'line-through' : 'none',
                              }}
                            >
                              {(service.unit === service.baseUnit && (
                                <span>{formatPriceCur(service.cost, service.currency + '/' + service.unit)}</span>
                              )) || (
                                <span title={formatPriceCur(service.baseUnitCost, service.currency + '/' + service.baseUnit)}>
                                  {formatPriceCur(service.cost, service.currency + '/' + service.unit)}
                                </span>
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableCustom>
                </Grid>
              )}
            </Box>

            <Box px={1}>
              <ServicesDropDown dealStore={dealStore} isViewOnly={isViewOnly} enabledServices={enabledServices} dealPosition={position} />
            </Box>
          </Box>
          <Box p={1}>
            <Typography variant="caption" fontWeight={600} color="text.secondary">
              Цена товара с услугами {formatPriceCur(position.baseFullUnitCost)} x {formatNumber3(position.baseQuantity)} {baseUnit}
            </Typography>
          </Box>
        </Box>
      </TabSelectWrapper>
    )
  );
};
