import { Box, Divider, Grid, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC, useEffect, useRef, useState } from 'react';
import { BillPosition, FreezePosition } from 'src/api/marketx';
import { TableRowTitle } from 'src/components/Catalog/Table/CatalogTableRowVirtualizeItemView';

type BillPositionAdditionalTabHeaderProps = {
  isDialogOpen: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  position: BillPosition | FreezePosition;
  dividerMb?: number;
};

export const BillPositionAdditionalTabHeader: FC<BillPositionAdditionalTabHeaderProps> = ({ position, Icon, isDialogOpen, dividerMb }) => {
  const [isInDialog, setIsInDialog] = useState(false);

  const parentRef = useRef(null);

  const mb = dividerMb ? dividerMb : 1;

  useEffect(() => {
    if (parentRef.current) {
      const hasClass = parentRef.current?.offsetParent?.classList.contains('MuiPaper-root');
      setIsInDialog(hasClass);
    }
  }, []);

  return (
    <Box ref={parentRef} sx={{ width: `${isInDialog ? '100%' : '0px'}` }}>
      {isDialogOpen && isInDialog && (
        <>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              <Icon sx={{ color: '#757575' }} />
            </Grid>
            <Grid item>
              <Typography fontSize={'12px'} color="text.secondary">
                <TableRowTitle item={position.product} target={'_blank'} />
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', mb: mb }} />
        </>
      )}
    </Box>
  );
};
